import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { useLocation, useNavigate } from "react-router-dom";
import {
    popRoute,
    changeStep,
    setAnimationDirection,
    setIsSecondaryMenu
} from "./redux/slices/windowSlice";
import {
    closingBehavior,
    retrieveLaunchParams,
    backButton
} from "@telegram-apps/sdk";

const App: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const launchParams = retrieveLaunchParams();

    const { routeStack, projectId, isSecondaryMenu } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    closingBehavior.mount();
    closingBehavior.enableConfirmation();
    
    
    useEffect(() => {
        const handleBackButtonClick = () => {
            if (routeStack.length > 0) {
                const previousRoute = routeStack[routeStack.length - 1];

                if(isSecondaryMenu) {
                    dispatch(setIsSecondaryMenu(false));
                } else if (previousRoute && previousRoute.path && previousRoute.step) {
                    dispatch(setAnimationDirection(previousRoute.swipe));
                    dispatch(popRoute());
                    dispatch(changeStep(previousRoute.step)); // Обновляем состояние шага
                    navigate(`${projectId}/${previousRoute.path}`, {replace: true});  // Перемещаемся на предыдущий маршрут
                } else {
                    console.error("Invalid previousRoute:", previousRoute);
                }
            }
        };

        backButton.mount();
        backButton.show();

        backButton.onClick(handleBackButtonClick);
        if (routeStack.length === 1) {
            backButton.hide();
        } else {
            backButton.show();
        }

        return () => {
            backButton.offClick( handleBackButtonClick);
        };
    }, [routeStack, dispatch, navigate, isSecondaryMenu]);

    if (launchParams.platform === "ios" || launchParams.platform === "macos") {
        document.documentElement.classList.add("apple");
        document.documentElement.classList.remove("material");
    } else {
        document.documentElement.classList.remove("apple");
        document.documentElement.classList.add("material");
    }

    return <div>{children}</div>;
};

export default App;