import "./style.scss";

const LangButton = ({ icon, color, lang, onClick }) => {
    return (
        <div
            className="lang-button"
            onClick={onClick}
            style={{
                backgroundColor: color,
            }}>
            <img
                className="icon-button_img"
                src={icon}
                alt="icon"
                style={{ backgroundColor: color }}
            />
            {lang && (
                <span
                    className="lang-button_text"
                    style={{
                        backgroundColor: color,
                    }}>
                    {lang}
                </span>
            )}
        </div>
    );
};

export default LangButton;
