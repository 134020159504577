// utils.js
const styleMappings = {
    mt: 'marginTop',
    mb: 'marginBottom',
    ml: 'marginLeft',
    mr: 'marginRight',
    pt: 'paddingTop',
    pb: 'paddingBottom',
    pl: 'paddingLeft',
    pr: 'paddingRight',
};

export const applySx = (sx = {}) => {
    return Object.entries(sx).reduce((acc, [key, value]) => {
        const styleKey = styleMappings[key] || camelToKebabCase(key);
        acc[styleKey] = value;
        return acc;
    }, {});
};

const camelToKebabCase = (str) =>
    str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

export const createRipple = (event, containerRef, setRippleStyle, setIsRippling) => {
    const container = containerRef.current;
    const rect = container.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    setRippleStyle({
        top: `${y}px`,
        left: `${x}px`,
        width: `${size}px`,
        height: `${size}px`,
    });

    setIsRippling(true);

    setTimeout(() => {
        setIsRippling(false);
    }, 600); 
};
