import Localized from '../Localized';

export const getLocalizedPeriod = (period_value: number, period_value_unit: string): string => {
    const units = {
        minute: Localized.Minute,
        hour: Localized.Hour,
        day: Localized.Day,
        month: Localized.Month,
        year: Localized.Year,
    };

    const unit = units[period_value_unit.toLowerCase()];
    if (!unit) {
        throw new Error(`Unknown period unit: ${period_value_unit}`);
    }

    let localizedUnit;
    if (Localized.getLanguage() === 'ru') {
        if (period_value % 10 === 1 && period_value % 100 !== 11) {
            localizedUnit = unit[0];
        } else if ([2, 3, 4].includes(period_value % 10) && ![12, 13, 14].includes(period_value % 100)) {
            localizedUnit = unit[1];
        } else {
            localizedUnit = unit[2];
        }
    } else {
        localizedUnit = period_value === 1 ? unit[0] : unit[1];
    }

    return `${period_value} ${localizedUnit}`;
};