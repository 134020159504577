import React, { useEffect, useRef } from "react";
import salute from "./anim.json";
import Lottie from "lottie-react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import "./style.scss";

const Salute = () => {
    const { saluteCounter } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const lottieRef = useRef(null);

    useEffect(() => {
        if (saluteCounter > 0 && lottieRef.current) {
            lottieRef.current.stop();
            lottieRef.current.play();
        }
    }, [saluteCounter]);

    if (saluteCounter > 0) {
        return (
            <div className="anim">
                <Lottie
                    animationData={salute}
                    loop={false}
                    lottieRef={lottieRef}
                    onComplete={() => lottieRef.current.pause()}
                />
            </div>
        );
    }

    return null;
};

export default Salute;
