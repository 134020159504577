import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "../MySubscriptions/style.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
    navigateToPage,
    setSelectedAvailablePlan
} from "../../redux/slices/windowSlice";

import SubscriptionButton from "../../components/SubscriptionButton";
import ListButtons from "../../components/ListButtons";
import { RootState } from "../../app/store";
import { AvailablePlan } from "../../redux/types/window";
import Localized from "../../Localized";
import Lottie from "lottie-react";
import lottie from "../../assets/output.json";

const MySubscriptions = () => {
    const dispatch = useAppDispatch();

    const { availablePlans } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const openPlan = (AvailablePlan: AvailablePlan) => {
        dispatch(setSelectedAvailablePlan(AvailablePlan));
        dispatch(navigateToPage("description"));
    };

    const getDateTypeAndValue = (plan) => {
        if (plan.price) {
            return { dateType: "renew_type", dateValue: plan.price.interval };
        } 
        return { dateType: "takeplace", dateValue: null };
    };

    return (
        <div className="my-subscriptions">
            <div className="my-subscriptions_container">
                <div className="my-subscriptions_wrapper">
                    {
                        availablePlans.length > 0 && (
                            <ListButtons>
                                {availablePlans.map((subscription) => {
                                    const { dateType, dateValue } = getDateTypeAndValue(subscription);
                                    return (
                                        <SubscriptionButton
                                            key={subscription.id}
                                            title={subscription.title}
                                            dateType={dateType}
                                            dateValue={null}
                                            price={`${subscription.price.amount} ${subscription.price.currency}`}
                                            image={""}
                                            action={() => openPlan(subscription)}
                                        />
                                    );
                                })}
                            </ListButtons>
                        )
                    }

                    {availablePlans.length === 0 && (
                        <div className="my-subscriptions_empty">
                            <div className="my-subscriptions_empty_lottie">
                                <Lottie
                                    animationData={lottie}
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </div>

                            <span className="my-subscriptions_empty_text">
                                {Localized.NoAvailableSubscriptions}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MySubscriptions;
