import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { navigateToPage, needSalute } from "../../redux/slices/windowSlice";
import { popup } from "@telegram-apps/sdk";
import { useLoadPaymentMethods } from "../../hooks/useLoadPaymentMethods";
import { useSubscribeMutation } from "../../redux/api";
import { getLocalizedPeriod } from "../../utils/getLocalizedPeriod";
import Button from "../../components/Button";
import SliderButton from "../../components/SliderButton";
import ListButtons from "../../components/ListButtons";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import defaultIcon from "../../img/icons/dark/image.svg";
import "./style.scss";
import Localized from "../../Localized";

const SubscriptionNew = () => {
    const dispatch = useAppDispatch();
    const [subscribeState, setSubscribeState] = useState("ready");
    const { projectId, selectedPlan, savedCards, isSelectedPlanTrial } =
        useAppSelector((state: RootState) => state.windowSlice);

    const { loadPaymentMethods, isLoading, data, isError } =
        useLoadPaymentMethods(projectId);
    const [
        subscribe,
        {
            data: subscribeData,
            isLoading: isLoadingSubscribe,
            isError: isErrorSubscribe,
            isSuccess: isSuccessSubscribe,
        },
    ] = useSubscribeMutation();

    useEffect(() => {
        if (!isLoading && data && !isError) {
            dispatch(navigateToPage("add_new_card"));
        }
    }, [data, isError, isLoading]);

    const payAndSubscribe = () => {
        setSubscribeState("processing");
        subscribe({
            project_id: projectId,
            plan_id: selectedPlan.id || selectedPlan.hash,
            is_trial: isSelectedPlanTrial,
        })
            .unwrap()
            .then(() => {
                setSubscribeState("success");
                dispatch(needSalute());
                popup.open({
                    title: Localized.SubscriptionNewPage.SubscriptionSuccess,
                    message: Localized.SubscriptionNewPage.JoinProjectGroup,
                    buttons: [
                        {
                            id: "accept",
                            type: "default",
                            text: Localized.SubscriptionNewPage.Close,
                        },
                    ],
                });
            })
            .catch(() => {
                setSubscribeState("failure");
                setTimeout(() => {
                    setSubscribeState("ready");
                }, 4000);
            });
    };

    const formatString = (template, variables) => {
        return template.replace(/{(\w+)}/g, (_, key) => variables[key] || "");
    };

    return (
        <div className="subscription-info">
            <div className="subscription-info_container">
                <div className="subscription-info_wrapper">
                    <div className="subscription-info_main-block">
                        <div className="subscription-info_main-block_information">
                            <div className="subscription-info_main-block_information_icon">
                                <img
                                    src=""
                                    alt=""
                                    className="subscription-info_main-block_information_icon_img"
                                />
                                <img
                                    src={defaultIcon}
                                    alt="Image"
                                    className="subscription-info_main-block_information_icon_img-default"
                                />
                            </div>
                            <div className="subscription-info_main-block_information_details">
                                <span className="subscription-info_main-block_information_details_title">
                                    {selectedPlan.title}
                                </span>
                                <span className="subscription-info_main-block_information_details_plan">
                                    {selectedPlan.title_friendly}
                                </span>
                            </div>
                        </div>
                        <div className="subscription-info_main-block_plan-details">
                            {isSelectedPlanTrial ? (
                                selectedPlan.trial_price?.amount === 0 ? (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">
                                            {" "}
                                            {formatString(
                                                Localized.SubscriptionNewPage
                                                    .FreeTrialThen,
                                                {
                                                    amount: selectedPlan.price
                                                        .amount,
                                                    currency:
                                                        selectedPlan.price
                                                            .currency,
                                                    localized_interval:
                                                        getLocalizedPeriod(
                                                            selectedPlan.price
                                                                .interval,
                                                            selectedPlan.price
                                                                .interval_unit
                                                        ),
                                                }
                                            )}
                                        </span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">
                                            {formatString(
                                                Localized.SubscriptionNewPage
                                                    .TrialPeriodPayment,
                                                {
                                                    trial_interval:
                                                        selectedPlan.trial_price
                                                            .trial_interval,
                                                    trial_interval_unit:
                                                        selectedPlan.trial_price
                                                            .trial_interval_unit,
                                                    amount: selectedPlan.price
                                                        .amount,
                                                    currency:
                                                        selectedPlan.price
                                                            .currency,
                                                }
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">
                                            {formatString(
                                                Localized.SubscriptionNewPage
                                                    .TrialPeriodFor,
                                                {
                                                    trial_amount:
                                                        selectedPlan.trial_price
                                                            .amount,
                                                    trial_currency:
                                                        selectedPlan.trial_price
                                                            .currency,
                                                    amount: selectedPlan.price
                                                        .amount,
                                                    currency:
                                                        selectedPlan.price
                                                            .currency,
                                                    localized_interval:
                                                        getLocalizedPeriod(
                                                            selectedPlan.price
                                                                .interval,
                                                            selectedPlan.price
                                                                .interval_unit
                                                        ),
                                                }
                                            )}
                                        </span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">
                                            {formatString(
                                                Localized.SubscriptionNewPage
                                                    .TrialPeriodPayment,
                                                {
                                                    trial_interval:
                                                        selectedPlan.trial_price
                                                            .trial_interval,
                                                    trial_interval_unit:
                                                        selectedPlan.trial_price
                                                            .trial_interval_unit,
                                                    amount: selectedPlan.price
                                                        .amount,
                                                    currency:
                                                        selectedPlan.price
                                                            .currency,
                                                }
                                            )}
                                        </span>
                                    </>
                                )
                            ) : (
                                <>
                                    <span className="subscription-info_main-block_plan-details_price">
                                        {selectedPlan.price.amount}{" "}
                                        {selectedPlan.price.currency} /{" "}
                                        {getLocalizedPeriod(
                                            selectedPlan.price.interval,
                                            selectedPlan.price.interval_unit
                                        )}
                                    </span>
                                    <span className="subscription-info_main-block_plan-details_renew-information">
                                        {
                                            Localized.SubscriptionNewPage
                                                .PayNowActivate
                                        }
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                    {savedCards.length > 0 ? (
                        <>
                            <SliderButton
                                card={savedCards[0]}
                                state={subscribeState}
                                onPaymentStart={payAndSubscribe}
                                type={
                                    isSelectedPlanTrial ? "trial" : "subscribe"
                                }
                            />
                            <span className="subscription-info_text">
                                {Localized.SubscriptionNewPage.Or}
                            </span>
                        </>
                    ) : (
                        <span className="subscription-info_text-warning">
                            {isSelectedPlanTrial
                                ? Localized.SubscriptionNewPage
                                      .CardRequiredForTrial
                                : Localized.SubscriptionNewPage
                                      .CardRequiredForPayment}
                        </span>
                    )}

                    <ListButtons>
                        <Button
                            title={Localized.AddNewCard}
                            icon={creditCardDark}
                            action={loadPaymentMethods}
                            isLoading={isLoading}
                        />
                    </ListButtons>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionNew;
