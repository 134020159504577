import "./style.scss";

const IconButton = ({ icon, color, text, onClick }) => {
    return (
        <div
            className="icon-button"
            onClick={onClick}
            style={{
                backgroundColor: color,
            }}>
            <img className="icon-button_img" src={icon} alt="icon" style={{ backgroundColor: color }} />
            {
                text && <span className="icon-button_text">{text}</span>
            }
        </div>
    );
};

export default IconButton;
