import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./style.scss";
import ListButtons from "../../components/ListButtons";
import CustomButton from "../../components/Button";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import addNew from "../../img/icons/dark/add-new.svg";
import fire from "../../img/icons/dark/fire.svg";
import { popup, viewport, openLink } from "@telegram-apps/sdk";
import { navigateToPage, setTrialMode } from "../../redux/slices/windowSlice";
import { RootState } from "../../app/store";
import { useLoadSavedCards } from "../../hooks/useLoadSavedCards";

import Modal from "rsuite/Modal";
import "rsuite/Modal/styles/index.css";
import Button from "rsuite/Button";
import "rsuite/Button/styles/index.css";
import Localized from "../../Localized";
import { getLocalizedPeriod } from "../../utils/getLocalizedPeriod";

const DescriptionProject = () => {
    const dispatch = useAppDispatch();
    const [moreText, setMoreText] = useState(false);
    const [isLoadingSubscribeBtn, SetLoadingSubscribeBtn] = useState(false);
    const [isLoadingTrialBtn, SetLoadingTrialBtn] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const containerRef = useRef(null);
    const descriptionRef = useRef(null);

    const { selectedPlan, projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const {
        loadSavedCards,
        isLoading: isLoadingSavedCards,
        data: savedCards,
        isError: isErrorSavedCards,
    } = useLoadSavedCards(projectId);
    const [modalOpen, setModalOpen] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState("");

    const toggleMoreText = () => {
        setMoreText(!moreText);
        initializeViewport();
    };

    const initializeViewport = () => {
        viewport.expand();
    };

    const selectTrialMode = () => {
        dispatch(setTrialMode(true));
        SetLoadingTrialBtn(true);
        loadSavedCards();
    };

    const selectNotTrialMode = () => {
        console.log("tse");
        
        SetLoadingSubscribeBtn(true);
        loadSavedCards();
    };

    useEffect(() => {
        dispatch(setTrialMode(false));
    }, []);

    useEffect(() => {
        if (!isLoadingSavedCards && savedCards && !isErrorSavedCards) {
            dispatch(navigateToPage("subscription_new"));
            SetLoadingSubscribeBtn(false);
            SetLoadingTrialBtn(false);
        }
    }, [savedCards, isErrorSavedCards, isLoadingSavedCards]);

    useEffect(() => {
        if (descriptionRef.current) {
            const height = descriptionRef.current.offsetHeight;
            setShowMoreButton(height > 75);
        }
    }, [selectedPlan.description_message]);

    const subscribe = (type: string) => {
        console.log(type);
        
        switch (type) {
            case "subscribe":
                selectNotTrialMode();
                break;
            case "trial":
                selectTrialMode();
                break;
            default:
                break;
        }
    };

    const selectPlan = (type: string) => {
        setSubscriptionType(type);
        if (selectedPlan.rule_url) {
            // setModalOpen(true);
            popup
            .open({
                title: Localized.PaymentSettingsPage.Confirmation,
                message: selectedPlan.text_public_contract,
                buttons: [
                    {
                        id: "desc-my-id",
                        type: "default",
                        text: Localized.Accept,
                    },
                    {
                        id: "desc-my-id-2",
                        type: "default",
                        text: Localized.Cancel,
                    },
                    {
                        id: "desc-my-id-3",
                        type: "default",
                        text: Localized.ReadContract,
                    },
                ],
            })
            .then((buttonId) => {
                if (buttonId === "desc-my-id") {
                    subscribe(type);  
                }
                if (buttonId === "desc-my-id-3") {
                    openLink(selectedPlan.rule_url);
                }
                console.log(buttonId);
                
            });
        } else {
            subscribe("type");
        }
    };

    

    return (
        <div
            className={`description-project ${moreText ? "expanded" : ""}`}
            ref={containerRef}>
            <div className="description-project_container">
                <div className="description-project_wrapper">
                    <span className="description-project_text">
                        {Localized.ProjectDescription}
                    </span>
                    <div className="description-project_drop-down">
                        <span
                            className="description-project_description"
                            ref={descriptionRef}>
                            {selectedPlan.description_message}
                        </span>
                    </div>
                    {showMoreButton && (
                        <div
                            className="description-project_open-more"
                            onClick={toggleMoreText}>
                            <span className="description-project_open-more_text">
                                {Localized.ShowMore}
                            </span>
                        </div>
                    )}
                    <ListButtons sx={{ mt: 40, mb: 40 }}>
                        <CustomButton
                            title={`${Localized.Subscribe} • ${
                                selectedPlan.price.amount
                            } ${
                                selectedPlan.price.currency
                            } / ${getLocalizedPeriod(
                                selectedPlan.price.interval,
                                selectedPlan.price.interval_unit
                            )}`}
                            icon={addNew}
                            action={() => selectPlan("subscribe")}
                            bgColor="#29B6BD"
                            isLoading={isLoadingSubscribeBtn}
                        />
                        {selectedPlan.trial_price && (
                            <CustomButton
                                title={`${Localized.Try} • ${
                                    selectedPlan.trial_price?.amount
                                } ${
                                    selectedPlan.trial_price?.currency
                                } / ${getLocalizedPeriod(
                                    selectedPlan.trial_price?.trial_interval,
                                    selectedPlan.trial_price
                                        ?.trial_interval_unit
                                )}`}
                                icon={fire}
                                action={() => selectPlan("trial")}
                                bgColor="#FFCC00"
                                isLoading={isLoadingTrialBtn}
                            />
                        )}
                    </ListButtons>
                </div>
            </div>
            {/* <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="xs">
                <Modal.Body>{selectedPlan.text_public_contract}</Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => subscribe()}
                        appearance="primary"
                        loading={isLoadingSavedCards}>
                        {Localized.Accept}
                    </Button>
                    <Button
                        onClick={() => setModalOpen(false)}
                        appearance="subtle">
                        {Localized.Cancel}
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
};

export default DescriptionProject;
