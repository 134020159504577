import React, { useEffect, useState } from "react";
import "./style.scss";
import { CircularProgress } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";

import { popup, viewport } from '@telegram-apps/sdk';
import { useLocation } from "react-router-dom";
import { needSalute, popRoute } from "../../redux/slices/windowSlice";
import { useLoadSavedCards } from "../../hooks/useLoadSavedCards";
import Localized from "../../Localized";

const BepaidContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [showBePaidLoading, setShowBePaidLoading] = useState(false);
    const { paymentStep: { type, token }, projectId, routeStack } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { loadSavedCards, isLoading, data, isError } = useLoadSavedCards(projectId);

    useEffect(() => {
        if (!isLoading && data && !isError) {
            dispatch(popRoute(2));
        } 
    }, [data, isError, isLoading]);

    useEffect(() => {
        setShowBePaidLoading(true);

        return () => {
            if (location.pathname !== `/${projectId}/bepaid`) {
                const bepaidApps = document.querySelectorAll(".bepaid-app");
                bepaidApps.forEach((bepaidApp) => {
                    bepaidApp.remove();
                });
            }
        };
    }, [location.pathname, projectId, routeStack]);

    useEffect(() => {
        return () => {
            const bepaidApps = document.querySelectorAll(".bepaid-app");
            bepaidApps.forEach((bepaidApp) => {
                bepaidApp.remove();
            });
        };
    }, []);

    useEffect(() => {
        const initializeViewport = () => {
            viewport.expand(); 
        };
        initializeViewport();
    }, [viewport]);
    
    const BepaidAlert = ({title, text}) => {
        popup
            .open({
                title: title,
                message: text,
                buttons: [
                    { id: "my-id", type: "default", text: Localized.PaymentSettingsPage.CloseWindow },
                ],
            })
    };

    const openBePaidWidget = () => {
        const params = {
            checkout_url: "https://checkout.bepaid.by",
            token: token,
            fromWebview: true,
            closeWidget: function (status: string | null) {
                console.debug("close widget callback", status);
                if (status === "successful") {
                    BepaidAlert({
                        title: Localized.BePaidContainer.Success,
                        text: Localized.BePaidContainer.CardAdded,
                    });
                    dispatch(needSalute());
                    loadSavedCards();
                } else if (status === "failed") {
                    BepaidAlert({
                        title: Localized.BePaidContainer.ErrorOccurred,
                        text: Localized.BePaidContainer.CardAddProblem,
                    });
                    dispatch(popRoute(1));
                } else if (status === "error") {
                    BepaidAlert({
                        title: Localized.BePaidContainer.ErrorOccurred,
                        text: Localized.BePaidContainer.CardAddProblem,
                    });
                    dispatch(popRoute(1));
                } else if (status === null) {
                    BepaidAlert({
                        title: Localized.BePaidContainer.ErrorOccurred,
                        text: Localized.BePaidContainer.CardAddProblem,
                    });
                    dispatch(popRoute(1));
                }
            },
        };
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        new window.BeGateway(params).createWidget();
    };


    useEffect(() => {
        if (type === "bepaid" && token) {
            openBePaidWidget();
        }
    }, [type]);

    return (
        <div className="payment-settings">
            <div className="payment-settings_container">
                {showBePaidLoading && (
                    <div className="loading-message">
                        <CircularProgress
                            variant="indeterminate"
                            size={25}
                            thickness={4}
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[800]
                                        : theme.palette.grey[100],
                            }}
                        />
                        <span className="loading-message_text">
                           {Localized.BePaidContainer.CardInputWindow}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BepaidContainer;