// src/hooks/useLoadSavedCards.ts
import { useState, useEffect, useCallback } from "react";
import { useGetSavedCardsQuery } from "../redux/api";

export const useLoadSavedCards = (projectId: string) => {
    const [shouldFetch, setShouldFetch] = useState(false);
    const [data, setSavedCards] = useState(null);
    const { data: savedCardsData, isLoading, isError, refetch } = useGetSavedCardsQuery(
        { project_id: projectId },
        { skip: !shouldFetch, refetchOnMountOrArgChange: true }
    );

    useEffect(() => {
        if (shouldFetch) {
            refetch();
        }
    }, [shouldFetch, refetch]);

    useEffect(() => {
        if (!isLoading && savedCardsData && !isError) {
            setSavedCards(savedCardsData);
            setShouldFetch(false);
        } else if (!isLoading && isError) {
            setShouldFetch(false);
        }
    }, [savedCardsData, isError, isLoading]);

    const loadSavedCards = useCallback(() => {
        setSavedCards(null); // Сброс данных перед новой загрузкой
        setShouldFetch(true);
    }, []);

    return { loadSavedCards, isLoading, data, isError };
};