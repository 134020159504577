import React, { useEffect, useState } from "react";
import "./style.scss";
import { CircularProgress } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

import { viewport } from '@telegram-apps/sdk';
import CheckoutForm from "./CheckoutForm";

const StripeContainer: React.FC = () => {
    const { paymentStep: { type, token, public_key } } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    useEffect(() => {
        const initializeViewport = () => {
            viewport.expand(); 
        };
        initializeViewport();
    }, [viewport]);

    return (
        <div className="payment-settings">
            <div className="payment-settings_container">
                {type == "stripe" && token && (
                    <div className="stripe-overlay">
                        <div className="stripe-modal">
                            <CheckoutForm token={token} public_key={public_key}/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StripeContainer;