// src/hooks/useLoadPaymentMethods.ts
import { useState, useEffect, useCallback } from "react";
import { useGetPaymentMethodsQuery } from "../redux/api";

export const useLoadPaymentMethods = (projectId: string) => {
    const [shouldFetch, setShouldFetch] = useState(false);
    const { data, isLoading, isError, refetch } = useGetPaymentMethodsQuery(
        { project_id: projectId },
        { skip: !shouldFetch, refetchOnMountOrArgChange: true }
    );

    useEffect(() => {
        if (shouldFetch) {
            refetch();
        }
    }, [shouldFetch, refetch]);

    useEffect(() => {
        if (!isLoading && data && !isError) {
            setShouldFetch(false);
        } else if (!isLoading && isError) {
            setShouldFetch(false);
        }
    }, [data, isError, isLoading]);

    const loadPaymentMethods = useCallback(() => {
        setShouldFetch(true);
    }, []);

    return { loadPaymentMethods, isLoading, data, isError };
};