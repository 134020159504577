import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    useParams,
} from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import {compareVersions} from 'compare-versions';

import "./css/normalize.css";
import "./style.scss";
import Header from "./components/Header";
import MySubscriptions from "./pages/MySubscriptions";
import MainScreen from "./pages/MainScreen";
import SubscriptionInfo from "./pages/SubscriptionInfo";
import App from "./App";
import AvailableSubscriptions from "./pages/AvailableSubscriptions";
import SubscriptionNew from "./pages/SubscriptionNew";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StatusWrapper from "./components/StatusWrapper";
import PaymentSettngs from "./pages/PaymentSettings";
import AddNewPaymentCardChoise from "./pages/AddNewPaymentCardChoise";
import BepaidContainer from "./pages/BepaidContainer";
import NotAvailable from "./pages/NotAvailable";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import Initializer from "./components/Initializer";
import StripeContainer from "./pages/StripeContainer";
import DescriptionProject from "./pages/DescriptionProject";
import WelcomePage from "./pages/WelcomePage";
import Salute from "./components/Salute";

interface ProjectChildProps {
    project_id?: string;
}

const ProjectRoutes = ({ children }: { children: React.ReactNode }) => {
    const { project_id } = useParams<{ project_id: string }>();

    return (
        <Routes>
            {React.Children.map(children, (child) => {
                if (React.isValidElement<ProjectChildProps>(child)) {
                    return React.cloneElement(child, { project_id });
                }
                return child;
            })}
        </Routes>
    );
};

const AnimatedRoutes = () => {
    const nodeRef = useRef(null);
    const location = useLocation(); // Получаем текущее местоположение
    const navigate = useNavigate();
    const launchParams = retrieveLaunchParams();
    const { animationDirection } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);

    return (
        <ProjectRoutes> 
            
            <Route path="/:project_id">
                {/* <Route path="welcome"  element={<WelcomePage /> } /> */}
                <Route index element={compareVersions(launchParams.version, "6.9") === -1 ? <NotAvailable /> : <MainScreen />} />
                <Route path="my_subscriptions">
                    <Route index element={<MySubscriptions />} />
                    <Route path=":subscription_id" element={<SubscriptionInfo />} />
                </Route>
                <Route path="available_subscriptions" element={<AvailableSubscriptions />} />
                <Route path="subscription_info" element={<SubscriptionInfo />} />
                <Route path="subscription_new" element={<SubscriptionNew />} />
                <Route path="payment_settings" element={<PaymentSettngs />} />
                <Route path="add_new_card" element={<AddNewPaymentCardChoise />} />
                <Route path="bepaid" element={<BepaidContainer />} />
                <Route path="stripe" element={<StripeContainer />} />
                <Route path="not_available" element={<NotAvailable />} />
                <Route path="description" element={<DescriptionProject />} />
            </Route>
        </ProjectRoutes>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Initializer>
                    <App>
                        <Header />
                        <AnimatedRoutes />
                        <ToastContainer />
                        
                        <Salute />
                    </App>
                </Initializer>
                <StatusWrapper />
            </Router>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
