import { retrieveLaunchParams } from '@telegram-apps/sdk';
import LocalizedStrings from 'react-localization';

let Localized = new LocalizedStrings({
    ru: {
      MySubscriptions: "Мои подписки",
      AvailableSubscription: "Доступные к оформлению",
      NoActiveSubscriptions: "У вас не было оформлено ещё ни одной подписки.",
      NoAvailableSubscriptions: "Нет доступных планов к оформлению.",
      PaymentSettngs: "Настройки оплаты",
      ManagerContact: "Связаться с менеджером",
      ReadContract: "Ознакомиться с договором",

      ActiveSubscription: "Активные",
      ExpiredSubscription: "Истёкшие",

      WillRenew: "Обновится",
      WillExpire: "Истекает",
      Expired: "Истекла",
      RegularPayment: "Оплата регулярная.",

      Takeplace: "Состоится",

      GoToChat: "Перейти в группу",

      CancelSubscription: "Отменить подписку",
      RenewSubscription: "Возобновить",

      AddNewCard: "Привязать новую карту",
      RemoveCard: "Удалить карту из системы",
      Card: "Карта",
      NoCardLinked: "К Вашему аккаунту ещё не привязана карта.",

      AddNewCardBelarus: "Карта банка РБ",
      AddNewCardRussia: "Карта банка РФ",
      AddNewCardOther: "Карта из зарубежа",

      Minute: ["минута", "минуты", "минут"],
      Hour: ["час", "часа", "часов"],
      Day: ["день", "дня", "дней"],
      Month: ["месяц", "месяца", "месяцев"],
      Year: ["год", "года", "лет"],

      Subscribe: "Оформить",
      Try: "Попробовать",
      ProjectDescription: "Описание проекта",
      ShowMore: "Показать полностью",

      Accept: "Принять",
      Cancel: "Отмена",
      SubscriptionInfo: "Информация о подписке",
      SubscriptionProcessing: "Оформление подписки",
      PaymentSettings: "Настройки оплаты",

      RecommendProject: "Посоветовать проект другу",
      Selected: "Выбрано:",
      ProceedToCheckout: "Перейти к оформлению",

      ErrorOccurred: "Произошла ошибка",
      RegistrationProblem: "Возникла проблема при регистрации аккаунта, обратитесь в поддержку Bot4Chat.",
      BotUnavailable: "Бот временно недоступен, попробуйте позже.",
      TelegramVersionUnsupported: "Ваша версия Телеграм не поддерживает наш бот. Обновите приложение или воспользуйтесь веб-версией мессенджера.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Точно отменяем?",
        ForceCancel: "Нет, всё равно отменить.",
        Stay: "Остаюсь за",
        Confirmation: "Подтверждение",
        SubscriptionChanged: "Подписка успешно изменена.",
        CloseWindow: "Закрыть окно",
        Error: "Ошибка",
        SubscriptionCancelled: "Подписка отменена",
        SubscriptionResumed: "Подписка возобновлена",
        BotWillRemove: "Бот автоматически удалит Вас из канала",
        WillTakePlace: "Состоится",
        NextCharge: "Следующее списание",
        SubscriptionExpired: "Срок действия подписки истёк",
        FreeTrialThen: "Бесплатный пробный период, далее",
        TrialPeriodFor: "Пробный период за",
        Then: ", далее",
      },

      PaymentSettingsPage: {
        Confirmation: "Подтверждение",
        ConfirmRemoveCard: "Вы действительно хотите удалить карту из системы? Это действие нельзя будет отменить.",
        YesRemoveCard: "Да, удалить карту",
        Cancel: "Отменить",
        CardRemoved: "Карта успешно удалена из системы.",
        CloseWindow: "Закрыть окно",
        Error: "Ошибка",
        NoCardLinked: "К Вашему аккаунту ещё не привязана карта.",
        AddNewCard: "Привязать новую карту",
        RemoveCard: "Удалить карту",
      },

      SubscibeSlider: {
        StartTrial: "Оформить пробный период",
        SwipeToStart: "Проведите для оформления",
        Processing: "Идет оформление...",
        ThisMayTakeTime: "Это может занять время",
        NotProcessed: "Не оформилось",
        PayWithCard: "Оплатить картой ••••",
        SwipeToPay: "Проведите для оплаты",
        PaymentProcessing: "Идет оплата...",
        NotPaid: "Не оплатилось",
      },

      BePaidContainer: {
        Success: "Ура!",
        CardAdded: "Карта была успешно добавлена в Ваш аккаунт. Теперь Вы можете оплачивать и оформлять подписки на проекты.",
        ErrorOccurred: "Произошла ошибка",
        CardAddProblem: "Возникла проблема при добавлении карты, проверьте, пожалуйста, свою карту и попробуйте еще раз.",
        CardInputWindow: "Сейчас откроется окно ввода карты",
      },

      ChangePlan: {
        ConfirmPlanChange: "Точно меняем тариф?",
        CurrentPlanInfo: "У вас уже есть подписка {currentPlanTitle} по цене {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Вы уверены, что хотите сменить тариф на {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit}? Следующее списание произойдет {renewAt}.",
        KeepCurrentPlan: "Нет, оставить прежний тариф.",
        ChangePlan: "Да, сменить тариф.",
        PlanChanged: "Тариф успешно изменен!",
        ContinueUsingSubscription: "Вы можете дальше пользоваться подпиской.",
        Close: "Закрыть",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Подписка успешно оформлена!",
        JoinProjectGroup: "Теперь Вы можете вступить в группу проекта.",
        Close: "Закрыть",
        FreeTrialThen: "Бесплатный пробный период, далее {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "Через {trial_interval} {trial_interval_unit} оплата составит {amount} {currency}, если Вы не отмените подписку.",
        TrialPeriodFor: "Пробный период за {trial_amount} {trial_currency}, далее {amount} {currency} / {localized_interval}",
        PayNowActivate: "Оплатите прямо сейчас и подписка будет активирована.",
        Or: "или",
        CardRequiredForTrial: "Для оформления пробного периода необходимо привязать банковскую карту.",
        CardRequiredForPayment: "Необходимо привязать банковскую карту для оплаты.",
      },

    },
    pl: {
      MySubscriptions: "Moje subskrypcje",
      AvailableSubscription: "Dostępne do subskrypcji",
      PaymentSettngs: "Ustawienia płatności",
      ManagerContact: "Skontaktuj się z menedżerem",
      NoActiveSubscriptions: "Nie masz jeszcze żadnych aktywnych subskrypcji.",
      NoAvailableSubscriptions: "Brak dostępnych planów do subskrypcji.",
      ReadContract: "Zapoznaj się z umową",

      ActiveSubscription: "Aktywne",
      ExpiredSubscription: "Wygasłe",

      WillRenew: "Odnowi się",
      WillExpire: "Wygasa",
      Expired: "Wygasła",
      RegularPayment: "Płatność regularna.",

      Takeplace: "Odbędzie się",

      GoToChat: "Przejdź do grupy",

      CancelSubscription: "Anuluj subskrypcję",
      RenewSubscription: "Odnów subskrypcję",

      AddNewCard: "Dodaj nową kartę",
      RemoveCard: "Usuń kartę z systemu",
      Card: "Karta płatnicza",
      NoCardLinked: "Do Twojego konta nie jest jeszcze dodana karta.",

      AddNewCardBelarus: "Karta banku Białorusi",
      AddNewCardRussia: "Karta banku Rosji",
      AddNewCardOther: "Karta zagraniczna",

      Minute: ["minuta", "minuty", "minut"],
      Hour: ["godzina", "godziny", "godzin"],
      Day: ["dzień", "dni", "dni"],
      Month: ["miesiąc", "miesiące", "miesięcy"],
      Year: ["rok", "lata", "lat"],

      Subscribe: "Subskrybuj",
      Try: "Wypróbuj",
      ProjectDescription: "Opis projektu",
      ShowMore: "Pokaż więcej",

      Accept: "Zaakceptować",
      Cancel: "Anulować",
      SubscriptionInfo: "Informacje o subskrypcji",
      SubscriptionProcessing: "Przetwarzanie subskrypcji",
      PaymentSettings: "Ustawienia płatności",

      RecommendProject: "Poleć projekt znajomemu",
      Selected: "Wybrano:",
      ProceedToCheckout: "Przejdź do kasy",

      ErrorOccurred: "Wystąpił błąd",
      RegistrationProblem: "Wystąpił problem podczas rejestracji konta, skontaktuj się z pomocą techniczną Bot4Chat.",
      BotUnavailable: "Bot jest tymczasowo niedostępny, spróbuj ponownie później.",
      TelegramVersionUnsupported: "Twoja wersja Telegrama nie obsługuje naszego bota. Zaktualizuj aplikację lub skorzystaj z wersji internetowej komunikatora.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Na pewno anulować?",
        ForceCancel: "Nie, mimo to anuluj.",
        Stay: "Zostaję",
        Confirmation: "Potwierdzenie",
        SubscriptionChanged: "Subskrypcja została pomyślnie zmieniona.",
        CloseWindow: "Zamknij okno",
        Error: "Błąd",
        SubscriptionCancelled: "Subskrypcja anulowana",
        SubscriptionResumed: "Subskrypcja wznowiona",
        BotWillRemove: "Bot automatycznie usunie Cię z kanału",
        WillTakePlace: "Odbędzie się",
        NextCharge: "Następne obciążenie",
        SubscriptionExpired: "Subskrypcja wygasła",
        FreeTrialThen: "Bezpłatny okres próbny, następnie",
        TrialPeriodFor: "Okres próbny za",
        Then: ", następnie",
      },

      PaymentSettingsPage: {
        Confirmation: "Potwierdzenie",
        ConfirmRemoveCard: "Czy na pewno chcesz usunąć kartę z systemu? Tej operacji nie można cofnąć.",
        YesRemoveCard: "Tak, usuń kartę",
        Cancel: "Anulować",
        CardRemoved: "Karta została pomyślnie usunięta z systemu.",
        CloseWindow: "Zamknij okno",
        Error: "Błąd",
        NoCardLinked: "Do Twojego konta nie jest jeszcze przypisana karta.",
        AddNewCard: "Dodaj nową kartę",
        RemoveCard: "Usuń kartę",
      },

      SubscibeSlider: {
        StartTrial: "Rozpocznij okres próbny",
        SwipeToStart: "Przesuń, aby rozpocząć",
        Processing: "Przetwarzanie...",
        ThisMayTakeTime: "To może zająć trochę czasu",
        NotProcessed: "Nie przetworzono",
        PayWithCard: "Zapłać kartą ••••",
        SwipeToPay: "Przesuń, aby zapłacić",
        PaymentProcessing: "Przetwarzanie płatności...",
        NotPaid: "Nie zapłacono",
      },

      BePaidContainer: {
        Success: "Hurra!",
        CardAdded: "Karta została pomyślnie dodana do Twojego konta. Teraz możesz płacić i subskrybować projekty.",
        ErrorOccurred: "Wystąpił błąd",
        CardAddProblem: "Wystąpił problem podczas dodawania karty, sprawdź swoją kartę i spróbuj ponownie.",
        CardInputWindow: "Teraz otworzy się okno wprowadzania karty",
      },

      ChangePlan: {
        ConfirmPlanChange: "Na pewno zmieniamy plan?",
        CurrentPlanInfo: "Masz już subskrypcję {currentPlanTitle} za {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Czy na pewno chcesz zmienić plan na {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit}? Następne obciążenie nastąpi {renewAt}.",
        KeepCurrentPlan: "Nie, zostaw stary plan.",
        ChangePlan: "Tak, zmień plan.",
        PlanChanged: "Plan został pomyślnie zmieniony!",
        ContinueUsingSubscription: "Możesz dalej korzystać z subskrypcji.",
        Close: "Zamknij",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Subskrypcja została pomyślnie zakończona!",
        JoinProjectGroup: "Teraz możesz dołączyć do grupy projektu.",
        Close: "Zamknij",
        FreeTrialThen: "Bezpłatny okres próbny, następnie {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "Po {trial_interval} {trial_interval_unit} opłata wyniesie {amount} {currency}, jeśli nie anulujesz subskrypcji.",
        TrialPeriodFor: "Okres próbny za {trial_amount} {trial_currency}, następnie {amount} {currency} / {localized_interval}",
        PayNowActivate: "Zapłać teraz, a subskrypcja zostanie aktywowana.",
        Or: "lub",
        CardRequiredForTrial: "Aby skorzystać z okresu próbnego, musisz dodać kartę płatniczą.",
        CardRequiredForPayment: "Aby zapłacić, musisz dodać kartę płatniczą.",
      },
    },
    en: {
      MySubscriptions: "My Subscriptions",
      AvailableSubscription: "Available Subscriptions",
      PaymentSettngs: "Payment Settings",
      ManagerContact: "Contact Manager",
      NoActiveSubscriptions: "You have not subscribed to any plans yet.",
      NoAvailableSubscriptions: "No available plans for subscription.",
      ReadContract: "Read the contract",

      ActiveSubscription: "Active",
      ExpiredSubscription: "Expired",

      WillRenew: "Will Renew",
      WillExpire: "Expires",
      Expired: "Expired",
      RegularPayment: "Regular payment.",

      Takeplace: "Will Take Place",

      GoToChat: "Go to Group",

      CancelSubscription: "Cancel Subscription",
      RenewSubscription: "Renew Subscription",

      AddNewCard: "Add New Card",
      RemoveCard: "Remove Card from System",
      Card: "Card",
      NoCardLinked: "No card is linked to your account yet.",

      AddNewCardBelarus: "Belarus Bank Card",
      AddNewCardRussia: "Russia Bank Card",
      AddNewCardOther: "Foreign Bank Card",

      Minute: ["minute", "minutes"],
      Hour: ["hour", "hours"],
      Day: ["day", "days"],
      Month: ["month", "months"],
      Year: ["year", "years"],

      Subscribe: "Subscribe",
      Try: "Try",
      ProjectDescription: "Project Description",
      ShowMore: "Show More",

      Accept: "Accept",
      Cancel: "Cancel",
      SubscriptionInfo: "Subscription Info",
      SubscriptionProcessing: "Subscription Processing",
      PaymentSettings: "Payment Settings",

      RecommendProject: "Recommend project to a friend",
      Selected: "Selected:",
      ProceedToCheckout: "Proceed to checkout",

      ErrorOccurred: "An error occurred",
      RegistrationProblem: "There was a problem registering the account, please contact Bot4Chat support.",
      BotUnavailable: "The bot is temporarily unavailable, please try again later.",
      TelegramVersionUnsupported: "Your version of Telegram does not support our bot. Please update the app or use the web version of the messenger.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Are you sure you want to cancel?",
        ForceCancel: "No, cancel anyway.",
        Stay: "Stay",
        Confirmation: "Confirmation",
        SubscriptionChanged: "Subscription successfully changed.",
        CloseWindow: "Close window",
        Error: "Error",
        SubscriptionCancelled: "Subscription cancelled",
        SubscriptionResumed: "Subscription resumed",
        BotWillRemove: "The bot will automatically remove you from the channel",
        WillTakePlace: "Will take place",
        NextCharge: "Next charge",
        SubscriptionExpired: "Subscription expired",
        FreeTrialThen: "Free trial period, then",
        TrialPeriodFor: "Trial period for",
        Then: ", then",
      },

      PaymentSettingsPage: {
        Confirmation: "Confirmation",
        ConfirmRemoveCard: "Are you sure you want to remove the card from the system? This action cannot be undone.",
        YesRemoveCard: "Yes, remove card",
        Cancel: "Cancel",
        CardRemoved: "Card successfully removed from the system.",
        CloseWindow: "Close window",
        Error: "Error",
        NoCardLinked: "No card is linked to your account yet.",
        AddNewCard: "Add New Card",
        RemoveCard: "Remove Card",
      },

      SubscibeSlider: {
        StartTrial: "Start trial period",
        SwipeToStart: "Swipe to start",
        Processing: "Processing...",
        ThisMayTakeTime: "This may take time",
        NotProcessed: "Not processed",
        PayWithCard: "Pay with card ••••",
        SwipeToPay: "Swipe to pay",
        PaymentProcessing: "Payment processing...",
        NotPaid: "Not paid",
      },

      BePaidContainer: {
        Success: "Hooray!",
        CardAdded: "The card has been successfully added to your account. Now you can pay and subscribe to projects.",
        ErrorOccurred: "An error occurred",
        CardAddProblem: "There was a problem adding the card, please check your card and try again.",
        CardInputWindow: "The card input window will open now",
      },

      ChangePlan: {
        ConfirmPlanChange: "Are you sure you want to change the plan?",
        CurrentPlanInfo: "You already have a subscription {currentPlanTitle} for {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Are you sure you want to change the plan to {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit}? The next charge will occur on {renewAt}.",
        KeepCurrentPlan: "No, keep the current plan.",
        ChangePlan: "Yes, change the plan.",
        PlanChanged: "The plan has been successfully changed!",
        ContinueUsingSubscription: "You can continue using the subscription.",
        Close: "Close",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Subscription successfully completed!",
        JoinProjectGroup: "Now you can join the project group.",
        Close: "Close",
        FreeTrialThen: "Free trial period, then {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "After {trial_interval} {trial_interval_unit}, the payment will be {amount} {currency} if you do not cancel the subscription.",
        TrialPeriodFor: "Trial period for {trial_amount} {trial_currency}, then {amount} {currency} / {localized_interval}",
        PayNowActivate: "Pay now and the subscription will be activated.",
        Or: "or",
        CardRequiredForTrial: "A bank card is required to start the trial period.",
        CardRequiredForPayment: "A bank card is required for payment.",
      },
    },
    be: {
      MySubscriptions: "Мае падпіскі",
      AvailableSubscription: "Даступныя да афармлення",
      PaymentSettngs: "Налады аплаты",
      ManagerContact: "Звязацца з мэнэджарам",
      NoActiveSubscriptions: "У вас не было аформлена яшчэ ні адной падпіскі.",
      NoAvailableSubscriptions: "Няма даступных планаў для афармлення.",
      ReadContract: "Азнаёміцца з дамовай",

      ActiveSubscription: "Актыўныя",
      ExpiredSubscription: "Састарэлыя",

      WillRenew: "Абновіцца",
      WillExpire: "Сканае",
      Expired: "Скончылася",
      RegularPayment: "Рэгулярная аплата.",

      Takeplace: "Адбудзецца",

      GoToChat: "Перайсці ў групу",

      CancelSubscription: "Адмяніць падпіску",
      RenewSubscription: "Аднавіць падпіску",

      AddNewCard: "Дадаць новую карту",
      RemoveCard: "Выдаліць карту з сістэмы",
      Card: "Карта",
      NoCardLinked: "Да вашага уліковага запісу яшчэ не прывязана карта.",

      AddNewCardBelarus: "Карта банка РБ",
      AddNewCardRussia: "Карта банка РФ",
      AddNewCardOther: "Карта з-за мяжы",

      Minute: ["хвіліна", "хвіліны", "хвілін"],
      Hour: ["гадзіна", "гадзіны", "гадзін"],
      Day: ["дзень", "дні", "дзён"],
      Month: ["месяц", "месяцы", "месяцаў"],
      Year: ["год", "гады", "гадоў"],

      Subscribe: "Аформіць",
      Try: "Паспрабаваць",
      ProjectDescription: "Апісанне праекта",
      ShowMore: "Паказаць цалкам",

      Accept: "Прыняць",
      Cancel: "Адмена",
      SubscriptionInfo: "Інфармацыя аб падпісцы",
      SubscriptionProcessing: "Афармленне падпіскі",
      PaymentSettings: "Налады аплаты",

      RecommendProject: "Параіць праект сябру",
      Selected: "Выбрана:",
      ProceedToCheckout: "Перайсці да афармлення",

      ErrorOccurred: "Адбылася памылка",
      RegistrationProblem: "Узнікла праблема пры рэгістрацыі ўліковага запісу, звярніцеся ў службу падтрымкі Bot4Chat.",
      BotUnavailable: "Бот часова недаступны, паспрабуйце пазней.",
      TelegramVersionUnsupported: "Ваша версія Telegram не падтрымлівае наш бот. Абнавіце прыкладанне або скарыстайцеся вэб-версіяй мессенджара.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Дакладна адмяняем?",
        ForceCancel: "Не, усё роўна адмяніць.",
        Stay: "Застаюся",
        Confirmation: "Пацверджанне",
        SubscriptionChanged: "Падпіска паспяхова зменена.",
        CloseWindow: "Закрыць акно",
        Error: "Памылка",
        SubscriptionCancelled: "Падпіска адменена",
        SubscriptionResumed: "Падпіска адноўлена",
        BotWillRemove: "Бот аўтаматычна выдаліць вас з канала",
        WillTakePlace: "Адбудзецца",
        NextCharge: "Наступнае спісанне",
        SubscriptionExpired: "Тэрмін дзеяння падпіскі скончыўся",
        FreeTrialThen: "Бясплатны пробны перыяд, далей",
        TrialPeriodFor: "Пробны перыяд за",
        Then: ", далей",
      },

      PaymentSettingsPage: {
        Confirmation: "Пацверджанне",
        ConfirmRemoveCard: "Вы сапраўды хочаце выдаліць карту з сістэмы? Гэта дзеянне нельга будзе адмяніць.",
        YesRemoveCard: "Так, выдаліць карту",
        Cancel: "Адмена",
        CardRemoved: "Карта паспяхова выдалена з сістэмы.",
        CloseWindow: "Закрыць акно",
        Error: "Памылка",
        NoCardLinked: "Да вашай уліковага запісу яшчэ не прывязана карта.",
        AddNewCard: "Дадаць новую карту",
        RemoveCard: "Выдаліць карту",
      },

      SubscibeSlider: {
        StartTrial: "Аформіць пробны перыяд",
        SwipeToStart: "Правядзіце для афармлення",
        Processing: "Ідзе афармленне...",
        ThisMayTakeTime: "Гэта можа заняць час",
        NotProcessed: "Не аформілася",
        PayWithCard: "Аплаціць картай ••••",
        SwipeToPay: "Правядзіце для аплаты",
        PaymentProcessing: "Ідзе аплата...",
        NotPaid: "Не аплацілася",
      },

      BePaidContainer: {
        Success: "Ура!",
        CardAdded: "Карта была паспяхова дададзена ў ваш уліковы запіс. Цяпер вы можаце аплачваць і афармляць падпіскі на праекты.",
        ErrorOccurred: "Адбылася памылка",
        CardAddProblem: "Узнікла праблема пры даданні карты, праверце, калі ласка, сваю карту і паспрабуйце яшчэ раз.",
        CardInputWindow: "Зараз адкрыецца акно ўводу карты",
      },

      ChangePlan: {
        ConfirmPlanChange: "Дакладна мяняем тарыф?",
        CurrentPlanInfo: "У вас ужо ёсць падпіска {currentPlanTitle} па цане {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Вы ўпэўненыя, што хочаце змяніць тарыф на {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit}? Наступнае спісанне адбудзецца {renewAt}.",
        KeepCurrentPlan: "Не, пакінуць стары тарыф.",
        ChangePlan: "Так, змяніць тарыф.",
        PlanChanged: "Тарыф паспяхова зменены!",
        ContinueUsingSubscription: "Вы можаце далей карыстацца падпіскай.",
        Close: "Закрыць",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Падпіска паспяхова аформлена!",
        JoinProjectGroup: "Цяпер вы можаце далучыцца да групы праекта.",
        Close: "Закрыць",
        FreeTrialThen: "Бясплатны пробны перыяд, далей {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "Праз {trial_interval} {trial_interval_unit} аплата складзе {amount} {currency}, калі вы не адменіце падпіску.",
        TrialPeriodFor: "Пробны перыяд за {trial_amount} {trial_currency}, далей {amount} {currency} / {localized_interval}",
        PayNowActivate: "Аплаціце прама зараз і падпіска будзе актывавана.",
        Or: "ці",
        CardRequiredForTrial: "Для афармлення пробнага перыяду неабходна прывязаць банкаўскую карту.",
        CardRequiredForPayment: "Неабходна прывязаць банкаўскую карту для аплаты.",
      },
    },
    uk: {
      MySubscriptions: "Мої підписки",
      AvailableSubscription: "Доступні для оформлення",
      PaymentSettngs: "Налаштування оплати",
      ManagerContact: "Зв'язатися з менеджером",
      NoActiveSubscriptions: "У вас не було оформлено ще жодної підписки.",
      NoAvailableSubscriptions: "Немає доступних планів для оформлення.",
      ReadContract: "Ознайомитися з договором",

      ActiveSubscription: "Активні",
      ExpiredSubscription: "Закінчилися",

      WillRenew: "Оновиться",
      WillExpire: "Закінчується",
      Expired: "Закінчилася",
      RegularPayment: "Регулярна оплата.",

      Takeplace: "Відбудеться",

      GoToChat: "Перейти до групи",

      CancelSubscription: "Скасувати підписку",
      RenewSubscription: "Відновити підписку",

      AddNewCard: "Прив'язати нову карту",
      RemoveCard: "Видалити карту з системи",
      Card: "Карта",
      NoCardLinked: "До вашого облікового запису ще не прив'язана карта.",

      AddNewCardBelarus: "Карта банку Білорусі",
      AddNewCardRussia: "Карта банку Росії",
      AddNewCardOther: "Іноземна карта",

      Minute: ["хвилина", "хвилини", "хвилин"],
      Hour: ["година", "години", "годин"],
      Day: ["день", "дні", "днів"],
      Month: ["місяць", "місяці", "місяців"],
      Year: ["рік", "роки", "років"],

      Subscribe: "Оформити",
      Try: "Спробувати",
      ProjectDescription: "Опис проекту",
      ShowMore: "Показати повністю",

      Accept: "Прийняти",
      Cancel: "Скасувати",
      SubscriptionInfo: "Інформація про підписку",
      SubscriptionProcessing: "Оформлення підписки",
      PaymentSettings: "Налаштування оплати",

      RecommendProject: "Порадити проект другу",
      Selected: "Вибрано:",
      ProceedToCheckout: "Перейти до оформлення",

      ErrorOccurred: "Сталася помилка",
      RegistrationProblem: "Виникла проблема під час реєстрації облікового запису, зверніться до служби підтримки Bot4Chat.",
      BotUnavailable: "Бот тимчасово недоступний, спробуйте пізніше.",
      TelegramVersionUnsupported: "Ваша версія Телеграм не підтримує наш бот. Оновіть додаток або скористайтеся веб-версією месенджера.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Точно скасовуємо?",
        ForceCancel: "Ні, все одно скасувати.",
        Stay: "Залишаюся",
        Confirmation: "Підтвердження",
        SubscriptionChanged: "Підписка успішно змінена.",
        CloseWindow: "Закрити вікно",
        Error: "Помилка",
        SubscriptionCancelled: "Підписка скасована",
        SubscriptionResumed: "Підписка відновлена",
        BotWillRemove: "Бот автоматично видалить вас з каналу",
        WillTakePlace: "Відбудеться",
        NextCharge: "Наступне списання",
        SubscriptionExpired: "Термін дії підписки закінчився",
        FreeTrialThen: "Безкоштовний пробний період, далі",
        TrialPeriodFor: "Пробний період за",
        Then: ", далі",
      },

      PaymentSettingsPage: {
        Confirmation: "Підтвердження",
        ConfirmRemoveCard: "Ви дійсно хочете видалити карту з системи? Цю дію не можна буде скасувати.",
        YesRemoveCard: "Так, видалити карту",
        Cancel: "Скасувати",
        CardRemoved: "Карту успішно видалено з системи.",
        CloseWindow: "Закрити вікно",
        Error: "Помилка",
        NoCardLinked: "До вашого облікового запису ще не прив'язана карта.",
        AddNewCard: "Прив'язати нову карту",
        RemoveCard: "Видалити карту",
      },

      SubscibeSlider: {
        StartTrial: "Оформити пробний період",
        SwipeToStart: "Проведіть для оформлення",
        Processing: "Йде оформлення...",
        ThisMayTakeTime: "Це може зайняти час",
        NotProcessed: "Не оформилося",
        PayWithCard: "Оплатити карткою ••••",
        SwipeToPay: "Проведіть для оплати",
        PaymentProcessing: "Йде оплата...",
        NotPaid: "Не оплатилося",
      },

      BePaidContainer: {
        Success: "Ура!",
        CardAdded: "Карту було успішно додано до вашого облікового запису. Тепер ви можете оплачувати та оформлювати підписки на проекти.",
        ErrorOccurred: "Сталася помилка",
        CardAddProblem: "Виникла проблема під час додавання карти, перевірте, будь ласка, свою карту і спробуйте ще раз.",
        CardInputWindow: "Зараз відкриється вікно введення карти",
      },

      ChangePlan: {
        ConfirmPlanChange: "Точно змінюємо тариф?",
        CurrentPlanInfo: "У вас вже є підписка {currentPlanTitle} за ціною {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Ви впевнені, що хочете змінити тариф на {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit}? Наступне списання відбудеться {renewAt}.",
        KeepCurrentPlan: "Ні, залишити старий тариф.",
        ChangePlan: "Так, змінити тариф.",
        PlanChanged: "Тариф успішно змінено!",
        ContinueUsingSubscription: "Ви можете далі користуватися підпискою.",
        Close: "Закрити",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Підписка успішно оформлена!",
        JoinProjectGroup: "Тепер ви можете приєднатися до групи проекту.",
        Close: "Закрити",
        FreeTrialThen: "Безкоштовний пробний період, далі {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "Через {trial_interval} {trial_interval_unit} оплата складе {amount} {currency}, якщо ви не скасуєте підписку.",
        TrialPeriodFor: "Пробний період за {trial_amount} {trial_currency}, далі {amount} {currency} / {localized_interval}",
        PayNowActivate: "Оплатіть прямо зараз і підписка буде активована.",
        Or: "або",
        CardRequiredForTrial: "Для оформлення пробного періоду необхідно прив'язати банківську карту.",
        CardRequiredForPayment: "Необхідно прив'язати банківську карту для оплати.",
      },
    },
    de: {
      MySubscriptions: "Meine Abonnements",
      AvailableSubscription: "Verfügbar zum Abonnieren",
      PaymentSettngs: "Zahlungseinstellungen",
      ManagerContact: "Kontaktieren Sie den Manager",
      NoActiveSubscriptions: "Sie haben noch keine Abonnements abgeschlossen.",
      NoAvailableSubscriptions: "Keine verfügbaren Pläne zur Abonnierung.",
      ReadContract: "Vertrag lesen",

      ActiveSubscription: "Aktiv",
      ExpiredSubscription: "Abgelaufen",

      WillRenew: "Wird erneuert",
      WillExpire: "Läuft ab",
      Expired: "Abgelaufen",
      RegularPayment: "Regelmäßige Zahlung.",

      Takeplace: "Wird stattfinden",

      GoToChat: "Zur Gruppe gehen",

      CancelSubscription: "Abonnement kündigen",
      RenewSubscription: "Abonnement erneuern",

      AddNewCard: "Neue Karte hinzufügen",
      RemoveCard: "Karte aus dem System entfernen",
      Card: "Karte",
      NoCardLinked: "Ihrem Konto ist noch keine Karte zugeordnet.",

      AddNewCardBelarus: "Belarus Bankkarte",
      AddNewCardRussia: "Russland Bankkarte",
      AddNewCardOther: "Ausländische Bankkarte",

      Minute: ["Minute", "Minuten"],
      Hour: ["Stunde", "Stunden"],
      Day: ["Tag", "Tage"],
      Month: ["Monat", "Monate"],
      Year: ["Jahr", "Jahre"],

      Subscribe: "Abonnieren",
      Try: "Versuchen",
      ProjectDescription: "Projektbeschreibung",
      ShowMore: "Mehr anzeigen",

      Accept: "Akzeptieren",
      Cancel: "Stornieren",
      SubscriptionInfo: "Abonnementinformationen",
      SubscriptionProcessing: "Abonnementverarbeitung",
      PaymentSettings: "Zahlungseinstellungen",

      RecommendProject: "Projekt einem Freund empfehlen",
      Selected: "Ausgewählt:",
      ProceedToCheckout: "Zur Kasse gehen",

      ErrorOccurred: "Ein Fehler ist aufgetreten",
      RegistrationProblem: "Bei der Registrierung des Kontos ist ein Problem aufgetreten, bitte wenden Sie sich an den Bot4Chat-Support.",
      BotUnavailable: "Der Bot ist vorübergehend nicht verfügbar, bitte versuchen Sie es später erneut.",
      TelegramVersionUnsupported: "Ihre Version von Telegram unterstützt unseren Bot nicht. Bitte aktualisieren Sie die App oder verwenden Sie die Webversion des Messengers.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Sind Sie sicher, dass Sie stornieren möchten?",
        ForceCancel: "Nein, trotzdem stornieren.",
        Stay: "Bleiben",
        Confirmation: "Bestätigung",
        SubscriptionChanged: "Abonnement erfolgreich geändert.",
        CloseWindow: "Fenster schließen",
        Error: "Fehler",
        SubscriptionCancelled: "Abonnement storniert",
        SubscriptionResumed: "Abonnement wieder aufgenommen",
        BotWillRemove: "Der Bot wird Sie automatisch aus dem Kanal entfernen",
        WillTakePlace: "Wird stattfinden",
        NextCharge: "Nächste Abbuchung",
        SubscriptionExpired: "Abonnement abgelaufen",
        FreeTrialThen: "Kostenlose Testphase, dann",
        TrialPeriodFor: "Testzeitraum für",
        Then: ", dann",
      },

      PaymentSettingsPage: {
        Confirmation: "Bestätigung",
        ConfirmRemoveCard: "Möchten Sie die Karte wirklich aus dem System entfernen? Diese Aktion kann nicht rückgängig gemacht werden.",
        YesRemoveCard: "Ja, Karte entfernen",
        Cancel: "Stornieren",
        CardRemoved: "Karte erfolgreich aus dem System entfernt.",
        CloseWindow: "Fenster schließen",
        Error: "Fehler",
        NoCardLinked: "Ihrem Konto ist noch keine Karte zugeordnet.",
        AddNewCard: "Neue Karte hinzufügen",
        RemoveCard: "Karte entfernen",
      },

      SubscibeSlider: {
        StartTrial: "Testzeitraum starten",
        SwipeToStart: "Zum Starten wischen",
        Processing: "Verarbeitung...",
        ThisMayTakeTime: "Dies kann einige Zeit dauern",
        NotProcessed: "Nicht verarbeitet",
        PayWithCard: "Mit Karte bezahlen ••••",
        SwipeToPay: "Zum Bezahlen wischen",
        PaymentProcessing: "Zahlung wird verarbeitet...",
        NotPaid: "Nicht bezahlt",
      },

      BePaidContainer: {
        Success: "Hurra!",
        CardAdded: "Die Karte wurde erfolgreich zu Ihrem Konto hinzugefügt. Jetzt können Sie Projekte bezahlen und abonnieren.",
        ErrorOccurred: "Ein Fehler ist aufgetreten",
        CardAddProblem: "Beim Hinzufügen der Karte ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Karte und versuchen Sie es erneut.",
        CardInputWindow: "Das Karten-Eingabefenster wird jetzt geöffnet",
      },

      ChangePlan: {
        ConfirmPlanChange: "Sind Sie sicher, dass Sie den Plan ändern möchten?",
        CurrentPlanInfo: "Sie haben bereits ein Abonnement {currentPlanTitle} für {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Sind Sie sicher, dass Sie den Plan auf {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit} ändern möchten? Die nächste Abbuchung erfolgt am {renewAt}.",
        KeepCurrentPlan: "Nein, den alten Plan behalten.",
        ChangePlan: "Ja, den Plan ändern.",
        PlanChanged: "Der Plan wurde erfolgreich geändert!",
        ContinueUsingSubscription: "Sie können das Abonnement weiterhin nutzen.",
        Close: "Schließen",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Abonnement erfolgreich abgeschlossen!",
        JoinProjectGroup: "Jetzt können Sie der Projektgruppe beitreten.",
        Close: "Schließen",
        FreeTrialThen: "Kostenlose Testphase, dann {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "Nach {trial_interval} {trial_interval_unit} beträgt die Zahlung {amount} {currency}, wenn Sie das Abonnement nicht kündigen.",
        TrialPeriodFor: "Testzeitraum für {trial_amount} {trial_currency}, dann {amount} {currency} / {localized_interval}",
        PayNowActivate: "Jetzt bezahlen und das Abonnement wird aktiviert.",
        Or: "oder",
        CardRequiredForTrial: "Für die Testphase ist eine Bankkarte erforderlich.",
        CardRequiredForPayment: "Für die Zahlung ist eine Bankkarte erforderlich.",
      },
    },
    lv: {
      MySubscriptions: "Mani abonementi",
      AvailableSubscription: "Pieejams abonēšanai",
      PaymentSettngs: "Maksājumu iestatījumi",
      ManagerContact: "Sazinieties ar vadītāju",
      NoActiveSubscriptions: "Jums vēl nav neviena aktīva abonementa.",
      NoAvailableSubscriptions: "Nav pieejamu plānu abonēšanai.",
      ReadContract: "Izlasiet līgumu",

      ActiveSubscription: "Aktīvs",
      ExpiredSubscription: "Beidzies",

      WillRenew: "Atjaunosies",
      WillExpire: "Beidzas",
      Expired: "Beidzies",
      RegularPayment: "Regulārs maksājums.",

      Takeplace: "Notiks",

      GoToChat: "Iet uz grupu",

      CancelSubscription: "Atcelt abonementu",
      RenewSubscription: "Atjaunot abonementu",

      AddNewCard: "Pievienot jaunu karti",
      RemoveCard: "Noņemt karti no sistēmas",
      Card: "Karte",
      NoCardLinked: "Jūsu kontam vēl nav pievienota karte.",

      AddNewCardBelarus: "Baltkrievijas bankas karte",
      AddNewCardRussia: "Krievijas bankas karte",
      AddNewCardOther: "Ārzemju banka karte",

      Minute: ["minūte", "minūtes", "minūšu"],
      Hour: ["stunda", "stundas", "stundu"],
      Day: ["diena", "dienas", "dienu"],
      Month: ["mēnesis", "mēneši", "mēnešu"],
      Year: ["gads", "gadi", "gadu"],

      Subscribe: "Abonēt",
      Try: "Izmēģināt",
      ProjectDescription: "Projekta apraksts",
      ShowMore: "Rādīt vairāk",

      Accept: "Pieņemt",
      Cancel: "Atcelt",
      SubscriptionInfo: "Abonēšanas informācija",
      SubscriptionProcessing: "Abonēšanas apstrāde",
      PaymentSettings: "Maksājumu iestatījumi",

      RecommendProject: "Iesakiet projektu draugam",
      Selected: "Izvēlēts:",
      ProceedToCheckout: "Pāriet uz norēķiniem",

      ErrorOccurred: "Radās kļūda",
      RegistrationProblem: "Radās problēma, reģistrējot kontu, lūdzu, sazinieties ar Bot4Chat atbalstu.",
      BotUnavailable: "Bots īslaicīgi nav pieejams, lūdzu, mēģiniet vēlāk.",
      TelegramVersionUnsupported: "Jūsu Telegram versija neatbalsta mūsu botu. Lūdzu, atjauniniet lietotni vai izmantojiet ziņojumapmaiņas tīmekļa versiju.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Vai tiešām atcelt?",
        ForceCancel: "Nē, tomēr atcelt.",
        Stay: "Palieku",
        Confirmation: "Apstiprinājums",
        SubscriptionChanged: "Abonements veiksmīgi mainīts.",
        CloseWindow: "Aizvērt logu",
        Error: "Kļūda",
        SubscriptionCancelled: "Abonements atcelts",
        SubscriptionResumed: "Abonements atsākts",
        BotWillRemove: "Bots automātiski noņems jūs no kanāla",
        WillTakePlace: "Notiks",
        NextCharge: "Nākamais maksājums",
        SubscriptionExpired: "Abonements beidzies",
        FreeTrialThen: "Bezmaksas izmēģinājuma periods, pēc tam",
        TrialPeriodFor: "Izmēģinājuma periods par",
        Then: ", pēc tam",
      },

      PaymentSettingsPage: {
        Confirmation: "Apstiprinājums",
        ConfirmRemoveCard: "Vai tiešām vēlaties noņemt karti no sistēmas? Šo darbību nevar atsaukt.",
        YesRemoveCard: "Jā, noņemt karti",
        Cancel: "Atcelt",
        CardRemoved: "Karte veiksmīgi noņemta no sistēmas.",
        CloseWindow: "Aizvērt logu",
        Error: "Kļūda",
        NoCardLinked: "Jūsu kontam vēl nav pievienota karte.",
        AddNewCard: "Pievienot jaunu karti",
        RemoveCard: "Noņemt karti",
      },

      SubscibeSlider: {
        StartTrial: "Sākt izmēģinājuma periodu",
        SwipeToStart: "Pārvelciet, lai sāktu",
        Processing: "Notiek apstrāde...",
        ThisMayTakeTime: "Tas var aizņemt laiku",
        NotProcessed: "Nav apstrādāts",
        PayWithCard: "Maksāt ar karti ••••",
        SwipeToPay: "Pārvelciet, lai samaksātu",
        PaymentProcessing: "Notiek maksājuma apstrāde...",
        NotPaid: "Nav samaksāts",
      },

      BePaidContainer: {
        Success: "Ura!",
        CardAdded: "Karte tika veiksmīgi pievienota jūsu kontam. Tagad jūs varat maksāt un abonēt projektus.",
        ErrorOccurred: "Radās kļūda",
        CardAddProblem: "Radās problēma, pievienojot karti, lūdzu, pārbaudiet savu karti un mēģiniet vēlreiz.",
        CardInputWindow: "Tagad tiks atvērts kartes ievades logs",
      },

      ChangePlan: {
        ConfirmPlanChange: "Vai tiešām mainām tarifu?",
        CurrentPlanInfo: "Jums jau ir abonements {currentPlanTitle} par cenu {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Vai tiešām vēlaties mainīt tarifu uz {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit}? Nākamā maksa notiks {renewAt}.",
        KeepCurrentPlan: "Nē, atstāt veco tarifu.",
        ChangePlan: "Jā, mainīt tarifu.",
        PlanChanged: "Tarifs veiksmīgi mainīts!",
        ContinueUsingSubscription: "Jūs varat turpināt izmantot abonementu.",
        Close: "Aizvērt",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Abonements veiksmīgi pabeigts!",
        JoinProjectGroup: "Tagad jūs varat pievienoties projekta grupai.",
        Close: "Aizvērt",
        FreeTrialThen: "Bezmaksas izmēģinājuma periods, pēc tam {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "Pēc {trial_interval} {trial_interval_unit} maksājums būs {amount} {currency}, ja jūs neatcelsiet abonementu.",
        TrialPeriodFor: "Izmēģinājuma periods par {trial_amount} {trial_currency}, pēc tam {amount} {currency} / {localized_interval}",
        PayNowActivate: "Samaksājiet tagad, un abonements tiks aktivizēts.",
        Or: "vai",
        CardRequiredForTrial: "Lai sāktu izmēģinājuma periodu, ir nepieciešama bankas karte.",
        CardRequiredForPayment: "Lai veiktu maksājumu, ir nepieciešama bankas karte.",
      },
    },
    lt: {
      MySubscriptions: "Mano prenumeratos",
      AvailableSubscription: "Galima užsiprenumeruoti",
      PaymentSettngs: "Mokėjimo nustatymai",
      ManagerContact: "Susisiekti su vadybininku",
      NoActiveSubscriptions: "Jūs dar neturite jokių aktyvių prenumeratų.",
      NoAvailableSubscriptions: "Nėra galimų prenumeratos planų.",
      ReadContract: "Susipažinkite su sutartimi",

      ActiveSubscription: "Aktyvūs",
      ExpiredSubscription: "Pasibaigę",

      WillRenew: "Bus atnaujinta",
      WillExpire: "Pasibaigia",
      Expired: "Pasibaigė",
      RegularPayment: "Reguliarus mokėjimas.",

      Takeplace: "Įvyks",

      GoToChat: "Eiti į grupę",

      CancelSubscription: "Atšaukti prenumeratą",
      RenewSubscription: "Atnaujinti prenumeratą",

      AddNewCard: "Pridėti naują kortelę",
      RemoveCard: "Pašalinti kortelę iš sistemos",
      Card: "Kortelė",
      NoCardLinked: "Jūsų paskyrai dar nepridėta kortelė.",

      AddNewCardBelarus: "Baltarusijos banko kortelė",
      AddNewCardRussia: "Rusijos banko kortelė",
      AddNewCardOther: "Užsienio banko kortelė",

      Minute: ["minutė", "minutės", "minučių"],
      Hour: ["valanda", "valandos", "valandų"],
      Day: ["diena", "dienos", "dienų"],
      Month: ["mėnuo", "mėnesiai", "mėnesių"],
      Year: ["metai", "metai", "metų"],

      Subscribe: "Prenumeruoti",
      Try: "Išbandyti",
      ProjectDescription: "Projekto aprašymas",
      ShowMore: "Rodyti daugiau",

      Accept: "Priimti",
      Cancel: "Atšaukti",
      SubscriptionInfo: "Prenumeratos informacija",
      SubscriptionProcessing: "Prenumeratos apdorojimas",
      PaymentSettings: "Mokėjimo nustatymai",

      RecommendProject: "Rekomenduokite projektą draugui",
      Selected: "Pasirinkta:",
      ProceedToCheckout: "Eiti į apmokėjimą",

      ErrorOccurred: "Įvyko klaida",
      RegistrationProblem: "Registruojant paskyrą kilo problema, susisiekite su Bot4Chat palaikymo tarnyba.",
      BotUnavailable: "Botas laikinai nepasiekiamas, bandykite vėliau.",
      TelegramVersionUnsupported: "Jūsų Telegram versija nepalaiko mūsų boto. Atnaujinkite programėlę arba naudokitės žinučių siuntimo žiniatinklio versija.",

      SubscriptionInfoPage: {
        ConfirmCancel: "Ar tikrai atšaukti?",
        ForceCancel: "Ne, vis tiek atšaukti.",
        Stay: "Lieku",
        Confirmation: "Patvirtinimas",
        SubscriptionChanged: "Prenumerata sėkmingai pakeista.",
        CloseWindow: "Uždaryti langą",
        Error: "Klaida",
        SubscriptionCancelled: "Prenumerata atšaukta",
        SubscriptionResumed: "Prenumerata atnaujinta",
        BotWillRemove: "Botas automatiškai pašalins jus iš kanalo",
        WillTakePlace: "Įvyks",
        NextCharge: "Kitas mokėjimas",
        SubscriptionExpired: "Prenumerata pasibaigė",
        FreeTrialThen: "Nemokamas bandomasis laikotarpis, tada",
        TrialPeriodFor: "Bandomasis laikotarpis už",
        Then: ", tada",
      },

      PaymentSettingsPage: {
        Confirmation: "Patvirtinimas",
        ConfirmRemoveCard: "Ar tikrai norite pašalinti kortelę iš sistemos? Šio veiksmo negalima atšaukti.",
        YesRemoveCard: "Taip, pašalinti kortelę",
        Cancel: "Atšaukti",
        CardRemoved: "Kortelė sėkmingai pašalinta iš sistemos.",
        CloseWindow: "Uždaryti langą",
        Error: "Klaida",
        NoCardLinked: "Jūsų paskyrai dar nepridėta kortelė.",
        AddNewCard: "Pridėti naują kortelę",
        RemoveCard: "Pašalinti kortelę",
      },

      SubscibeSlider: {
        StartTrial: "Pradėti bandomąjį laikotarpį",
        SwipeToStart: "Perbraukite, kad pradėtumėte",
        Processing: "Vykdoma...",
        ThisMayTakeTime: "Tai gali užtrukti",
        NotProcessed: "Neapdorota",
        PayWithCard: "Mokėti kortele ••••",
        SwipeToPay: "Perbraukite, kad sumokėtumėte",
        PaymentProcessing: "Vykdoma mokėjimo apdorojimas...",
        NotPaid: "Neapmokėta",
      },
      
      BePaidContainer: {
        Success: "Valio!",
        CardAdded: "Kortelė sėkmingai pridėta prie jūsų paskyros. Dabar galite mokėti ir prenumeruoti projektus.",
        ErrorOccurred: "Įvyko klaida",
        CardAddProblem: "Pridedant kortelę kilo problema, patikrinkite savo kortelę ir bandykite dar kartą.",
        CardInputWindow: "Dabar atsidarys kortelės įvedimo langas",
      },

      ChangePlan: {
        ConfirmPlanChange: "Ar tikrai keičiame tarifą?",
        CurrentPlanInfo: "Jūs jau turite abonementą {currentPlanTitle} už {currentPlanPrice} {currentPlanCurrency} / {currentPlanInterval} {currentPlanIntervalUnit}. Ar tikrai norite pakeisti tarifą į {newPlanPrice} {newPlanCurrency} / {newPlanInterval} {newPlanIntervalUnit}? Kitas mokėjimas įvyks {renewAt}.",
        KeepCurrentPlan: "Ne, palikti seną tarifą.",
        ChangePlan: "Taip, pakeisti tarifą.",
        PlanChanged: "Tarifas sėkmingai pakeistas!",
        ContinueUsingSubscription: "Galite toliau naudotis prenumerata.",
        Close: "Uždaryti",
      },

      SubscriptionNewPage: {
        SubscriptionSuccess: "Prenumerata sėkmingai baigta!",
        JoinProjectGroup: "Dabar galite prisijungti prie projekto grupės.",
        Close: "Uždaryti",
        FreeTrialThen: "Nemokamas bandomasis laikotarpis, tada {amount} {currency} / {localized_interval}",
        TrialPeriodPayment: "Po {trial_interval} {trial_interval_unit} mokėjimas bus {amount} {currency}, jei neatšauksite prenumeratos.",
        TrialPeriodFor: "Bandomasis laikotarpis už {trial_amount} {trial_currency}, tada {amount} {currency} / {localized_interval}",
        PayNowActivate: "Mokėkite dabar ir prenumerata bus aktyvuota.",
        Or: "arba",
        CardRequiredForTrial: "Norint pradėti bandomąjį laikotarpį, reikia banko kortelės.",
        CardRequiredForPayment: "Norint atlikti mokėjimą, reikia banko kortelės.",
      },
    },
});

const { initData } = retrieveLaunchParams();
var lang = initData.user.languageCode;

Localized.setLanguage('ru');
if (lang === 'pl' || lang === 'en' || lang === 'be' || lang === 'uk' || lang === 'de' || lang === 'lv' || lang === 'lt') {
  Localized.setLanguage(lang);
}

export default Localized;
