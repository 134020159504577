import React from "react";
import "./style.scss";
import { applySx } from "../../utils";


const index = ({children, sx = {}}) => {
    const customStyle = applySx(sx);
    
    return (
        <div className="list-buttons" style={{...customStyle}}>
            {children}
        </div>
    );
};

export default index;
