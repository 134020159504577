import React, { useState, useRef, useEffect } from "react";
import { hapticFeedback } from '@telegram-apps/sdk';
import { Bounce, toast } from "react-toastify";
import "./style.scss";
import { setGlobalMsg } from "../../redux/slices/windowSlice";
import { useAppDispatch } from "../../app/hooks";
import Localized from "../../Localized";

const SliderButton = ({ sx = {}, variant = "primary", card, state, onPaymentStart, type = "subscribe" }) => {
    const dispatch = useAppDispatch();

    const [sliderValue, setSliderValue] = useState(0);
    const [isSliding, setIsSliding] = useState(false);
    const intervalRef = useRef(null);
    const sliderElementRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if (state === 'failure') {
            handlePaymentFailure();
        } else if (state === 'successful') {
            handlePaymentSuccess();
        }
    }, [state]);

    const getHapticIntensity = (value) => {
        if (value <= 40) return 'light';
        if (value <= 80) return 'soft';
        if (value <= 120) return 'medium';
        if (value <= 160) return 'rigid';
        return 'heavy';
    };

    const handleSliderChange = (event) => {
        if (state === 'processing' || state === 'failure') return;

        const value = event.target.value;
        setSliderValue(value);
    
        const percentage = (value / 200) * 100;
        const radius = Math.min(8, (value / 200) * 8);
        sliderElementRef.current.style.setProperty('--slider-progress', `${percentage}%`);
        sliderElementRef.current.style.setProperty('--slider-radius', `${radius}px`);
    
        const intensity = getHapticIntensity(sliderValue);
        hapticFeedback.impactOccurred(intensity);
    };
    
    const handleMouseDown = () => {
        if (state === 'processing' || state === 'failure') return;

        setIsSliding(true);
        
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                const intensity = getHapticIntensity(sliderValue);
                hapticFeedback.impactOccurred(intensity);
            }, 100);
        }
    };

    const handleMouseUp = () => {
        if (state === 'processing' || state === 'failure') return;

        setIsSliding(false);
        clearInterval(intervalRef.current);
        intervalRef.current = null;

        if (sliderValue >= 200) {
            onPaymentStart(); 
        } else {
            resetSlider();
        }
    };

    const handleTouchMove = (event) => {
        if (state === 'processing' || state === 'failure') return;

        const touch = event.touches[0];
        const rect = sliderElementRef.current.getBoundingClientRect();
        const offsetX = touch.clientX - rect.left;
        const percentage = Math.min(Math.max(offsetX / rect.width, 0), 1);
        const value = Math.round(percentage * 200);
        setSliderValue(value);
        const intensity = getHapticIntensity(sliderValue);
        hapticFeedback.impactOccurred(intensity);
    };

    const handlePaymentFailure = () => {
        hapticFeedback.notificationOccurred('error');
        if (containerRef.current) {
            containerRef.current.style.setProperty('--theme-slider-item', '#BD293D'); // Устанавливаем красный цвет
        }

        setTimeout(() => {
            resetSlider();
            dispatch(setGlobalMsg(""));
            if (containerRef.current) {
                containerRef.current.style.setProperty('--theme-slider-item', ''); // Возвращаем к исходному цвету
            }
        }, 4000); // Оставляем красный фон и сообщение на 4 секунды
    };

    const handlePaymentSuccess = () => {
        hapticFeedback.notificationOccurred('success');
        resetSlider();
    };

    const resetSlider = () => {
        setSliderValue((prevValue) => {
            const step = prevValue / 20;
            let currentValue = prevValue;

            const interval = setInterval(() => {
                currentValue -= step;
                const percentage = (currentValue / 200) * 100;
                const radius = Math.min(8, (currentValue / 200) * 8);

                if (sliderElementRef.current) {
                    sliderElementRef.current.style.setProperty('--slider-progress', `${percentage}%`);
                    sliderElementRef.current.style.setProperty('--slider-radius', `${radius}px`);
                }

                if (currentValue <= 0) {
                    clearInterval(interval);
                    setSliderValue(0);
                    if (sliderElementRef.current) {
                        sliderElementRef.current.style.setProperty('--slider-progress', `0%`);
                        sliderElementRef.current.style.setProperty('--slider-radius', `0px`);
                    }
                } else {
                    setSliderValue(currentValue);
                }
            }, 15);

            return prevValue;
        });
    };

    const customStyle = sx;

    const variantStyles = {
        primary: {
            '--button-title-color': 'var(--theme-white)', 
        },
        danger: {
            '--button-title-color': 'var(--theme-red)', 
        },
        pink: {
            '--button-title-color': 'var(--theme-pink)', 
        },
        green: {
            '--button-title-color': 'var(--theme-green)', 
        }
    };

    const getText = () => {
        if (type === "trial") {
            return {
                main: Localized.SubscibeSlider.StartTrial,
                secondary: Localized.SubscibeSlider.SwipeToStart,
                processing: Localized.SubscibeSlider.Processing,
                processingSecondary: Localized.SubscibeSlider.ThisMayTakeTime,
                failure: Localized.SubscibeSlider.NotProcessed
            };
        } else {
            return {
                main: `${Localized.SubscibeSlider.PayWithCard} ${card.last_4}`,
                secondary: Localized.SubscibeSlider.SwipeToPay,
                processing: Localized.SubscibeSlider.PaymentProcessing,
                processingSecondary: Localized.SubscibeSlider.ThisMayTakeTime,
                failure: Localized.SubscibeSlider.NotPaid
            };
        }
    };

    const text = getText();

    return (
        <div 
            ref={containerRef} // Ссылка на контейнер для управления CSS переменной
            className={`slider-button ${state === 'failure' ? 'payment-failed' : ''}`}
            style={{ ...customStyle, ...variantStyles[variant] }}
        >
            <span className={`slider-button_title ${isSliding || state === 'processing' || state === 'failure' ? 'hidden' : ''}`}>{text.secondary}</span>
            <span className={`slider-button_title second ${isSliding || state === 'processing' || state === 'failure' ? 'hidden' : ''}`}>{text.main}</span>
            <span className={`slider-button_title ${state === 'processing' ? '' : 'hidden'}`}>{text.processing}</span>
            <span className={`slider-button_title second ${state === 'processing' ? '' : 'hidden'}`}>{text.processingSecondary}</span>
            {state === 'failure' && (
                <span className="slider-button_title failure-message">{text.failure}</span>
            )}
            <input
                ref={sliderElementRef}
                type="range"
                min="0"
                max="200"
                value={sliderValue}
                onChange={handleSliderChange}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleMouseUp}
                disabled={state === 'processing' || state === 'failure'}
                className={`slider-button_slider ${isSliding || state === 'processing' || state === 'failure' ? 'no-shine' : ''}`}
            />
        </div>
    );
};

export default SliderButton;