import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { openTelegramLink, shareURL, popup } from "@telegram-apps/sdk";
import { format } from "date-fns";
import {
    useAcceptPropositionMutation,
    useCancelSubscriptionMutation,
    useRenewSubscriptionMutation,
} from "../../redux/api";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
    changeStep,
    navigateToPage,
    setSelectedMySubscription,
} from "../../redux/slices/windowSlice";
import Localized from "../../Localized";
import Button from "../../components/Button";
import ListButtons from "../../components/ListButtons";
import { useLoadMySubscriptions } from "../../hooks/useLoadMySubscriptions";
import { useLoadSavedCards } from "../../hooks/useLoadSavedCards";
import { getLocalizedPeriod } from "../../utils/getLocalizedPeriod";

import minusRedLight from "../../img/icons/light/minus-red.svg";
import linkPinkLight from "../../img/icons/light/link-pink.svg";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import supportIconDark from "../../img/icons/dark/support.svg";
import likeLight from "../../img/icons/light/like.svg";
import addNew from "../../img/icons/dark/add-new.svg";
import defaultIcon from "../../img/icons/dark/image.svg";

import "./style.scss";

const SubscriptionInfo = () => {
    const dispatch = useAppDispatch();
    const { subscription_id } = useParams<{ subscription_id: string }>();

    const [cancelSubscription, { isLoading: isLoadingCancelSubscription }] =
        useCancelSubscriptionMutation();
    const [acceptProposition, { isLoading: isLoadingAcceptProposition }] =
        useAcceptPropositionMutation();
    const [renewSubscription, { isLoading: isLoadingRenewSubscription }] =
        useRenewSubscriptionMutation();
    const { selectedMySubscription, projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const {
        loadSavedCards,
        isLoading: isLoadingSavedCards,
        data: savedCards,
        isError: isErrorSavedCards,
    } = useLoadSavedCards(projectId);
    const {
        loadMySubscriptions,
        isLoading: isLoadingMySubscriptions,
        data: mySubscriptions,
        isError: isErrorMySubscriptions,
    } = useLoadMySubscriptions(projectId);

    useEffect(() => {
        if (subscription_id) {
            dispatch(navigateToPage(`my_subscriptions/${subscription_id}`));

            loadMySubscriptions();
        }
    }, []);

    useEffect(() => {
        if (!isLoadingSavedCards && savedCards && !isErrorSavedCards) {
            dispatch(navigateToPage("payment_settings"));
        }
    }, [savedCards, isErrorSavedCards, isLoadingSavedCards]);

    useEffect(() => {
        if (subscription_id && mySubscriptions) {
            const selectedSubscription =
                mySubscriptions.active.find(
                    (sub) => sub.id === subscription_id
                ) ||
                mySubscriptions.inactive.find(
                    (sub) => sub.id === subscription_id
                );
            if (selectedSubscription) {
                dispatch(setSelectedMySubscription(selectedSubscription));
                dispatch(changeStep("subscription_info"));
            }
        }
    }, [subscription_id, mySubscriptions, dispatch]);

    const handlerCancelSubscription = async ({
        subscription_id,
        project_id,
        step = 0,
    }) => {
        const response = await cancelSubscription({
            project_id: project_id,
            subscription_id: subscription_id,
            step: step,
        });

        if (response.data.status === "proposition") {
            const { proposition } = response.data;
            const buttonId = await popup.open({
                title: Localized.SubscriptionInfoPage.ConfirmCancel,
                message: proposition.text,
                buttons: [
                    {
                        id: "cancel",
                        type: "destructive",
                        text: Localized.SubscriptionInfoPage.ForceCancel,
                    },
                    {
                        id: "accept",
                        type: "default",
                        text: `${Localized.SubscriptionInfoPage.Stay} ${
                            proposition.price.amount
                        } ${proposition.price.currency}/${getLocalizedPeriod(
                            proposition.price.interval,
                            proposition.price.interval_unit
                        )}`,
                    },
                ],
            });

            if (buttonId === "cancel") {
                handlerCancelSubscription({
                    subscription_id,
                    project_id,
                    step: step + 1,
                });
            } else {
                acceptProposition({
                    project_id: projectId,
                    subscription_id: selectedMySubscription.id,
                    step: step + 1,
                })
                    .unwrap()
                    .then(() => {
                        popup.open({
                            title: Localized.SubscriptionInfoPage.Confirmation,
                            message:
                                Localized.SubscriptionInfoPage
                                    .SubscriptionChanged,
                            buttons: [
                                {
                                    id: "success-ok",
                                    type: "default",
                                    text: Localized.SubscriptionInfoPage
                                        .CloseWindow,
                                },
                            ],
                        });
                    })
                    .catch((error) => {
                        popup.open({
                            title: Localized.SubscriptionInfoPage.Error,
                            message: error.data.error.error_msg,
                            buttons: [
                                {
                                    id: "error-ok",
                                    type: "default",
                                    text: Localized.SubscriptionInfoPage
                                        .CloseWindow,
                                },
                            ],
                        });
                    });
            }
        } else if (response.data.status === "success") {
            await popup.open({
                title: Localized.SubscriptionInfoPage.SubscriptionCancelled,
                message: response.data.message,
                buttons: [
                    {
                        id: "ok",
                        type: "default",
                        text: Localized.SubscriptionInfoPage.CloseWindow,
                    },
                ],
            });
        }
    };

    const handlerRenewSubscription = async ({
        subscription_id,
        project_id,
    }) => {
        const response = await renewSubscription({
            project_id: project_id,
            subscription_id: subscription_id,
        });

        if (response.data.status === "success") {
            await popup.open({
                title: Localized.SubscriptionInfoPage.SubscriptionResumed,
                message: response.data.message,
                buttons: [
                    {
                        id: "ok",
                        type: "default",
                        text: Localized.SubscriptionInfoPage.CloseWindow,
                    },
                ],
            });
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "dd.MM.yyyy HH:mm");
    };

    const getRenewInformation = (billingInformation, status) => {
        const { renew_at, valid_until } = billingInformation;

        if (renew_at === null && status === "canceled") {
            return (
                Localized.SubscriptionInfoPage.BotWillRemove +
                " " +
                formatDate(valid_until) +
                "."
            );
        } else if (renew_at === null && status === "active") {
            return (
                Localized.SubscriptionInfoPage.WillTakePlace +
                " " +
                formatDate(valid_until) +
                "."
            );
        } else if (
            renew_at !== null &&
            (status === "active" || status === "processing")
        ) {
            return (
                Localized.SubscriptionInfoPage.NextCharge +
                " " +
                formatDate(renew_at) +
                "."
            );
        } else if (status === "inactive") {
            return (
                Localized.SubscriptionInfoPage.SubscriptionExpired +
                " " +
                formatDate(valid_until) +
                "."
            );
        } else {
            return "";
        }
    };

    if (!selectedMySubscription) {
        return null;
    }

    return (
        <div className="subscription-new">
            <div className="subscription-new_container">
                <div className="subscription-new_wrapper">
                    <div className="subscription-new_main-block">
                        <div className="subscription-new_main-block_information">
                            <div className="subscription-new_main-block_information_icon">
                                <img
                                    src=""
                                    alt=""
                                    className="subscription-new_main-block_information_icon_img"
                                />
                                <img
                                    src={defaultIcon}
                                    alt="Image"
                                    className="subscription-info_main-block_information_icon_img-default"
                                />
                            </div>
                            <div className="subscription-new_main-block_information_details">
                                <span className="subscription-new_main-block_information_details_title">
                                    {selectedMySubscription.title}
                                </span>
                                <span className="subscription-new_main-block_information_details_plan">
                                    {selectedMySubscription.title_friendly}
                                </span>
                            </div>
                        </div>
                        <div className="subscription-info_main-block_plan-details">
                            {selectedMySubscription.is_trial ? (
                                selectedMySubscription.trial_price?.amount ===
                                0 ? (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">
                                            {
                                                Localized.SubscriptionInfoPage
                                                    .FreeTrialThen
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .amount
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .currency
                                            }{" "}
                                            /{" "}
                                            {getLocalizedPeriod(
                                                selectedMySubscription.price
                                                    .interval,
                                                selectedMySubscription.price
                                                    .interval_unit
                                            )}
                                        </span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">
                                            {getRenewInformation(
                                                selectedMySubscription.billing_information,
                                                selectedMySubscription.status
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">
                                            {
                                                Localized.SubscriptionInfoPage
                                                    .TrialPeriodFor
                                            }{" "}
                                            {
                                                selectedMySubscription
                                                    .trial_price?.amount
                                            }{" "}
                                            {
                                                selectedMySubscription
                                                    .trial_price?.currency
                                            }
                                            {
                                                Localized.SubscriptionInfoPage
                                                    .Then
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .amount
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .currency
                                            }{" "}
                                            /{" "}
                                            {getLocalizedPeriod(
                                                selectedMySubscription.price
                                                    .interval,
                                                selectedMySubscription.price
                                                    .interval_unit
                                            )}
                                        </span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">
                                            {getRenewInformation(
                                                selectedMySubscription.billing_information,
                                                selectedMySubscription.status
                                            )}
                                        </span>
                                    </>
                                )
                            ) : (
                                <>
                                    <span className="subscription-info_main-block_plan-details_price">
                                        {selectedMySubscription.price.amount}{" "}
                                        {selectedMySubscription.price.currency}{" "}
                                        /{" "}
                                        {getLocalizedPeriod(
                                            selectedMySubscription.price
                                                .interval,
                                            selectedMySubscription.price
                                                .interval_unit
                                        )}
                                    </span>
                                    <span className="subscription-info_main-block_plan-details_renew-information">
                                        {getRenewInformation(
                                            selectedMySubscription.billing_information,
                                            selectedMySubscription.status
                                        )}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                    <ListButtons sx={{ mb: 20 }}>
                        {selectedMySubscription.status != "inactive" &&
                            selectedMySubscription.group_invite_links.map(
                                (link, index) => (
                                    <Button
                                        key={index}
                                        title={`${Localized.GoToChat} (${link.group_title})`}
                                        icon={linkPinkLight}
                                        action={() => {
                                            openTelegramLink(
                                                link.group_invite_link
                                            );
                                        }}
                                        bgColor="#DE8BEB"
                                    />
                                )
                            )}
                        <Button
                            title={Localized.RecommendProject}
                            icon={likeLight}
                            action={() => {
                                shareURL(
                                    "https://t.me/testqdenyawebbot",
                                    "Рекомендую проект!"
                                );
                            }}
                            bgColor="#5856D6"
                        />
                    </ListButtons>

                    {["active", "processing"].includes(
                        selectedMySubscription.status
                    ) ? (
                        <ListButtons>
                            <Button
                                title={Localized.CancelSubscription}
                                icon={minusRedLight}
                                bgColor="#BD293D"
                                action={() =>
                                    handlerCancelSubscription({
                                        project_id: projectId,
                                        subscription_id:
                                            selectedMySubscription.id,
                                        step: 0,
                                    })
                                }
                                isLoading={
                                    isLoadingCancelSubscription ||
                                    isLoadingAcceptProposition
                                }
                            />
                            <Button
                                title={Localized.PaymentSettngs}
                                icon={creditCardDark}
                                action={loadSavedCards}
                                bgColor="#FF9F0A"
                                isLoading={isLoadingSavedCards}
                            />
                            <Button
                                title={Localized.ManagerContact}
                                icon={supportIconDark}
                                action={() => {
                                    openTelegramLink("https://t.me/RinaZG");
                                }}
                                bgColor="#AF52DE"
                            />
                        </ListButtons>
                    ) : (
                        selectedMySubscription.on_renew && (
                            <ListButtons>
                                <Button
                                    title={
                                        Localized.RenewSubscription +
                                        ` • ${
                                            selectedMySubscription.on_renew
                                                .price.amount
                                        } ${
                                            selectedMySubscription.on_renew
                                                .price.currency
                                        } / ${getLocalizedPeriod(
                                            selectedMySubscription.price
                                                .interval,
                                            selectedMySubscription.price
                                                .interval_unit
                                        )}`
                                    }
                                    icon={addNew}
                                    bgColor="#34C759"
                                    action={() =>
                                        handlerRenewSubscription({
                                            project_id: projectId,
                                            subscription_id:
                                                selectedMySubscription.id,
                                        })
                                    }
                                    isLoading={isLoadingRenewSubscription}
                                />
                            </ListButtons>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionInfo;
