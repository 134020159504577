import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../app/store';

export const api = createApi({
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).windowSlice.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    
  }),
  
  endpoints: (builder) => ({
    register: builder.mutation<any, { project_id: string, data: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/register`,
        method: 'POST',
        body: {
          data: data.data,
        },
      }),
    }),

    login: builder.mutation<any, { project_id: string, telegram_id: number, password: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/login`,
        method: 'POST',
        body: {
          telegram_id: data.telegram_id,
          password: data.password,
        },
      }),
    }),
    
    getBepaidToken: builder.mutation<any, { project_id: string, provider_id: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/account/bind_card`,
        method: 'PUT',
        body: {
          provider_id: data.provider_id
        },
      }),
    }),

    getPaymentMethods: builder.query<any, { project_id: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/payment_methods`,
        method: 'GET',
      }),
    }),

    getSavedCards: builder.query<any, { project_id: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/account/saved_cards`,
        method: 'GET',
      }),
    }),

    getMySubscriptions: builder.query<any, { project_id: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/subscriptions`,
        method: 'GET',
      }),
    }),

    getAvailablePlans: builder.query<any, { project_id: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/plans`,
        method: 'GET',
      }),
    }),

    getPlanByLink: builder.query<any, { project_id: string, hash_start: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/plan/${data.hash_start}`,
        method: 'GET',
      }),
    }),

    deleteSavedCard: builder.mutation<any, { project_id: string, card_id: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/account/card/${data.card_id}`,
        method: 'DELETE',
      }),
    }),

    subscribe: builder.mutation<any, { project_id: string, plan_id: string, is_trial?: boolean }>({
      query: (data) => ({
        url: `/project/${data.project_id}/plan/${data.plan_id}/subscribe`,
        method: 'POST',
        body: {
          use_trial: data.is_trial ? data.is_trial : false
        },
      }),
    }),

    cancelSubscription: builder.mutation<any, { project_id: string, subscription_id: string, step?: number }>({
      query: (data) => ({
        url: `/project/${data.project_id}/subscription/${data.subscription_id}/cancel`,
        method: 'POST',
        body: {
          step: data.step ? data.step : 0
        },
      }),
    }),

    renewSubscription: builder.mutation<any, { project_id: string, subscription_id: string }>({
      query: (data) => ({
        url: `/project/${data.project_id}/subscription/${data.subscription_id}/renew`,
        method: 'POST',
      }),
    }),

    acceptProposition: builder.mutation<any, { project_id: string, subscription_id: string, step: number }>({
      query: (data) => ({
        url: `/project/${data.project_id}/subscription/${data.subscription_id}/accept_proposition`,
        method: 'POST',
        body: {
          step: data.step
        },
      }),
    }),
  }),
  
});

export const { 
  useRegisterMutation,
  useGetBepaidTokenMutation,
  useLoginMutation,
  useGetPaymentMethodsQuery,
  useGetSavedCardsQuery,
  useDeleteSavedCardMutation,
  useGetMySubscriptionsQuery,
  useGetAvailablePlansQuery,
  useSubscribeMutation,
  useCancelSubscriptionMutation,
  useRenewSubscriptionMutation,
  useAcceptPropositionMutation,
  useGetPlanByLinkQuery
} = api;