import { useEffect, useState } from "react";

import Button from "../../components/Button";
import Localized from "../../Localized";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
    openTelegramLink,
    popup,
    retrieveLaunchParams,
    miniApp
} from "@telegram-apps/sdk";
import ListButtons from "../../components/ListButtons";
import { useLoadSavedCards } from "../../hooks/useLoadSavedCards";
import { useLoadMySubscriptions } from "../../hooks/useLoadMySubscriptions";
import { useLoadAvailablePlans } from "../../hooks/useLoadAvailablePlans";
import { useSubscribeMutation } from "../../redux/api";
import IconButton from "../../components/IconButton";
import LangButton from "../../components/LangButton";

import { navigateToPage } from "../../redux/slices/windowSlice";
import officeBagDark from "../../img/icons/dark/office-bag.svg";
import addDark from "../../img/icons/dark/add.svg";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import supportIconDark from "../../img/icons/dark/support.svg";
import langIcon from "../../img/icons/RoundIcons/lang.svg";
import accountIcon from "../../img/icons/RoundIcons/account.svg";
import lightThemeIcon from "../../img/icons/RoundIcons/sunset.svg";
import darkThemeIcon from "../../img/icons/RoundIcons/moon.svg";
import logob4c from "../../img/icons/logob4c.svg";

import "./style.scss";

const MainScreen = () => {
    const dispatch = useAppDispatch();
    const { initData } = retrieveLaunchParams();
    miniApp.mount();

    if (process.env.NODE_ENV !== "development") {
        document.documentElement.classList.contains("light") ? miniApp.setHeaderColor('#f3f3f7') : miniApp.setHeaderColor('#131415');
    }
    
    const { planByLinkInformation, projectId, welcomeText } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const {
        loadSavedCards,
        isLoading: isLoadingSavedCards,
        data: savedCards,
        isError: isErrorSavedCards,
    } = useLoadSavedCards(projectId);
    const {
        loadMySubscriptions,
        isLoading: isLoadingMySubscriptions,
        data: mySubscriptions,
        isError: isErrorMySubscriptions,
    } = useLoadMySubscriptions(projectId);
    const {
        loadAvailablePlans,
        isLoading: isLoadingAvailablePlans,
        data: availablePlans,
        isError: iseErrorAvailablePlans,
    } = useLoadAvailablePlans(projectId);
    const [
        subscribe,
        {
            data: subscribeData,
            isLoading: isLoadingSubscribe,
            isError: isErrorSubscribe,
            isSuccess: isSuccessSubscribe,
        },
    ] = useSubscribeMutation();

    const formatString = (template, variables) => {
        return template.replace(/{(\w+)}/g, (_, key) => variables[key] || "");
    };

    const userName = `${initData?.user?.firstName} ${initData?.user?.lastName}` || "User";

    useEffect(() => {
        if (!isLoadingSavedCards && savedCards && !isErrorSavedCards) {
            dispatch(navigateToPage("payment_settings"));
        }
    }, [savedCards, isErrorSavedCards, isLoadingSavedCards]);

    useEffect(() => {
        if (
            !isLoadingMySubscriptions &&
            mySubscriptions &&
            !isErrorMySubscriptions
        ) {
            dispatch(navigateToPage("my_subscriptions"));
        }
    }, [mySubscriptions, isErrorMySubscriptions, isLoadingMySubscriptions]);

    useEffect(() => {
        if (
            !isLoadingAvailablePlans &&
            availablePlans &&
            !iseErrorAvailablePlans
        ) {
            dispatch(navigateToPage("available_subscriptions"));
        }
    }, [availablePlans, iseErrorAvailablePlans, isLoadingAvailablePlans]);

    useEffect(() => {
        const handlePopup = async () => {
            if (
                planByLinkInformation &&
                planByLinkInformation.current_plan &&
                planByLinkInformation.new_plan
            ) {
                const localizedString = formatString(
                    Localized.ChangePlan.CurrentPlanInfo,
                    {
                        currentPlanTitle:
                            planByLinkInformation.current_plan.title,
                        currentPlanPrice:
                            planByLinkInformation.current_plan.price.amount,
                        currentPlanCurrency:
                            planByLinkInformation.current_plan.price.currency,
                        currentPlanInterval:
                            planByLinkInformation.current_plan.price.interval,
                        currentPlanIntervalUnit:
                            planByLinkInformation.current_plan.price
                                .interval_unit,
                        newPlanPrice:
                            planByLinkInformation.new_plan.price.amount,
                        newPlanCurrency:
                            planByLinkInformation.new_plan.price.currency,
                        newPlanInterval:
                            planByLinkInformation.new_plan.price.interval,
                        newPlanIntervalUnit:
                            planByLinkInformation.new_plan.price.interval_unit,
                        renewAt:
                            planByLinkInformation.new_plan.billing_information
                                .renew_at,
                    }
                );
                const buttonId = await popup.open({
                    title: Localized.ChangePlan.ConfirmPlanChange,
                    message: localizedString,
                    buttons: [
                        {
                            id: "cancel",
                            type: "default",
                            text: Localized.ChangePlan.KeepCurrentPlan,
                        },
                        {
                            id: "accept",
                            type: "default",
                            text: Localized.ChangePlan.ChangePlan,
                        },
                    ],
                });

                if (buttonId === "accept") {
                    subscribe({
                        project_id: projectId,
                        plan_id: planByLinkInformation.new_plan.hash,
                    })
                        .unwrap()
                        .then(() => {
                            popup.open({
                                title: Localized.ChangePlan.PlanChanged,
                                message:
                                    Localized.ChangePlan
                                        .ContinueUsingSubscription,
                                buttons: [
                                    {
                                        id: "accept",
                                        type: "default",
                                        text: Localized.ChangePlan.Close,
                                    },
                                ],
                            });
                        });
                }
            }
        };

        handlePopup();
    }, [planByLinkInformation]);

    const [language, setLanguage] = useState("EN");
    const [theme, setTheme] = useState("light");
    const [toggleDropDownState, setToggleDropDownState] = useState(false);

    useEffect(() => {
        setLanguage(initData.user.languageCode);
        document.documentElement.classList.contains("light") ? setTheme("light") : setTheme("dark");
    }, []);

    // useEffect(() => {
    //     const onClick = (e) =>  {
    //         if(e.target.className !== 'dropdown_content_button') {
    //             setToggleDropDownState(false);
    //         } 
    //     };
    //     document.addEventListener('click', onClick);
    //     return () => document.removeEventListener('click', onClick);
    //   }, []);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        Localized.setLanguage(lang);
        setToggleDropDownState(!toggleDropDownState);
    };

    const handleThemeChange = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);

        document.documentElement.classList.remove(theme);
        document.documentElement.classList.add(newTheme);

        if (process.env.NODE_ENV !== "development") {
            document.documentElement.classList.contains("light") ? miniApp.setHeaderColor('#f3f3f7') : miniApp.setHeaderColor('#131415');
        }

        
    };

    function toggleDropDown() {
        setToggleDropDownState(!toggleDropDownState);
    }

    const languages = [
        { lang: "be", name: "Беларуская" },
        { lang: "ru", name: "Русский" },
        { lang: "uk", name: "Українська" },
        { lang: "pl", name: "Polski" },
        { lang: "en", name: "English" },
        { lang: "de", name: "Deutsch" },
    ]

    return (
        <div className="main-page">
            <div className="main-page_container">
                <div className="main-page_wrapper">
                    <span className="main-page_welcometext">{welcomeText}</span>
                    <div className="main-page_header">
                        <div className="main-page_header_name">
                            <IconButton
                                icon={accountIcon}
                                color="#5a4fff"
                                text={userName}
                                onClick={() => {}}
                            />
                        </div>
                        <div className="main-page_header_settings">
                            <div className="dropdown">
                                    <LangButton
                                        icon={langIcon}
                                        color="#8B0AFC"
                                        lang={language.toUpperCase()}
                                        onClick={() => toggleDropDown()}
                                    />
                                    <div className={`${toggleDropDownState && 'active'} dropdown_content`}>
                                        {languages.map((item, index) => (
                                            <div className="dropdown_content_button" key={index} onClick={() => handleLanguageChange(item.lang)}>{item.name}</div>
                                        ))}
                                    </div>
                                </div>
                                <IconButton
                                    icon={theme === "light" ? lightThemeIcon : darkThemeIcon}
                                    color={theme === "light" ? "#FC9F0A" : "#540698"}
                                    text=""
                                    onClick={handleThemeChange}
                                />
                                
                            </div>
                    </div>
                    <ListButtons>
                        <Button
                            title={Localized.MySubscriptions}
                            icon={officeBagDark}
                            action={loadMySubscriptions}
                            bgColor="#007AFF"
                            isLoading={isLoadingMySubscriptions}
                        />
                        <Button
                            title={Localized.AvailableSubscription}
                            icon={addDark}
                            action={loadAvailablePlans}
                            bgColor="#34C759"
                            isLoading={isLoadingAvailablePlans}
                        />
                        <Button
                            title={Localized.PaymentSettngs}
                            icon={creditCardDark}
                            action={loadSavedCards}
                            bgColor="#FF9F0A"
                            isLoading={isLoadingSavedCards}
                        />
                        <Button
                            title={Localized.ManagerContact}
                            icon={supportIconDark}
                            action={() => {
                                openTelegramLink("https://t.me/RinaZG");
                            }}
                            bgColor="#AF52DE"
                        />
                    </ListButtons>
                    <div className="main-page_powered">
                        <img src={logob4c} alt="Razorpay" />
                        <span>Powered By BOT FOR CHAT</span>
                    </div>
                    {/* <p className="main-page_dbg">ID: {initData.user.id}</p> */}
                </div>
            </div>
        </div>
    );
};

export default MainScreen;
